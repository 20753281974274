import { forwardRef, useEffect, useImperativeHandle } from 'react';

import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import DocumentPreview from 'components/ComplexComponents/DocumentPreview';
import DynamicForm from 'components/ComplexComponents/DynamicForm';
import TraceabilityFieldset from 'features/traceabilities/components/TraceabilityFieldset';
import { TraceabilitySaverExtended } from 'modules/traceabilities';
import { useTraceabilityExtra } from './hooks/useTraceabilityExtra';
import { useTraceabilityForm } from './hooks/useTraceabilityForm';
import './TraceabilityForm.scss';

export type TraceabilityFormProps = {
    traceabilityId?: string | number;
    traceability?: Partial<TraceabilitySaverExtended>;
    hideSubmitBtn?: boolean;
    disableNotifyOnSave?: boolean;
};

export type TraceabilityFormRef = {
    save: (
        overrides?: Partial<TraceabilitySaverExtended>
    ) => Promise<{ data: TraceabilitySaverExtended; error: null } | { data: null; error: Error }>;
};

const TraceabilityForm = forwardRef<TraceabilityFormRef, TraceabilityFormProps>((props, ref) => {
    const { traceability, error, isPending, setTraceability, ...form } = useTraceabilityForm(props);
    const extra = useTraceabilityExtra(props);

    const save = async (overrides?: Partial<TraceabilitySaverExtended>) => {
        const payload = { ...(traceability || {}), ...(overrides || {}) };

        const extraResult = await extra.submit?.();
        if (extraResult?.errors) return { data: null, error: new Error('Error en el formulario extra') };

        const formResult = await form.save(payload);
        return formResult;
    };

    useEffect(() => {
        if (!props.traceability) return;
        setTraceability((prev) => ({ ...prev, ...props.traceability }));
    }, [JSON.stringify(props.traceability || {})]);

    // Expose submit method to allow external form submission
    useImperativeHandle(ref, () => ({ save }), [traceability, save]);

    const modifiers = [error ? 'TraceabilityForm--has-error' : '', isPending ? 'TraceabilityForm--is-loading' : ''];

    return (
        <div className={`TraceabilityForm ${modifiers.join(' ')}`} data-testid="TraceabilityForm">
            {error && <ErrorCard className="card__alert-error TraceabilityForm__errorMsg" error={error} />}

            <div className="TraceabilityForm__main">
                <DocumentPreview
                    persistDocument={props.traceabilityId !== undefined} // only upload when edit mode
                    className="TraceabilityForm__preview"
                    documentId={traceability?.di?.id}
                    documentName={traceability?.di?.name} // To reset files after traceability POST
                    traceabilityId={props.traceabilityId}
                    onDocumentChange={(doc) => {
                        const di = doc.id || doc.bytes ? (doc as TraceabilitySaverExtended['di']) : null;
                        setTraceability({ ...traceability, di });
                    }}
                />

                <div className="TraceabilityForm__form">
                    <TraceabilityFieldset
                        key={JSON.stringify(traceability?.id || 'new')} // Forces form to dismount when traceability changes
                        initialTraceabilityData={traceability}
                        error={error}
                        onChange={(form) => {
                            setTraceability({ ...traceability, ...form });
                        }}
                    ></TraceabilityFieldset>
                </div>
            </div>

            {extra.schema.data && (
                <DynamicForm
                    className="TraceabilityForm__extra"
                    ref={extra.$ref}
                    schema={extra.schema.data}
                    defaultValues={traceability.extra}
                    onChanges={function (extra) {
                        setTraceability({ ...traceability, extra });
                    }}
                />
            )}

            <footer className="TraceabilityForm__footer">
                <div style={{ marginLeft: 'auto' }} />
                {!props.hideSubmitBtn && (
                    <CreateButton
                        isLoading={isPending}
                        disabled={isPending}
                        extraClasses="TraceabilityForm__save"
                        type="button"
                        icon={null}
                        text="Guardar"
                        action={save}
                        style={{ ...(props.hideSubmitBtn ? { display: 'none' } : {}) }}
                    />
                )}
            </footer>
        </div>
    );
});

export default TraceabilityForm;
