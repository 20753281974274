import { IconCheck, IconX } from '@tabler/icons';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Because this is an infinite scroll list item to ensure performance,
 * we import icons as images instead of React components
 */
import IconCloudUploadGrey16 from 'assets/iconos/automatic-reading/icon-cloud-upload-grey-16.svg';
import IconMailGrey16 from 'assets/iconos/automatic-reading/icon-mail-grey-16.svg';
import IconUserGrey16 from 'assets/iconos/automatic-reading/icon-user-grey-16.svg';

import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import AutomaticReadingStatus from 'features/automatic-reading/components/AutomaticReadingStatus';
import DiExtraction from 'features/di-extraction/components/DiExtraction';
import DiExtractionWarning from 'features/di-extraction/components/DiExtractionWarnings/DiExtractionWarning';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { DiExtrationStatus, IDiExtraction, useDiExtraction } from 'modules/di-extraction';
import { WasteFlowProvider, httpWasteFlowApi } from 'modules/waste-flow';
import AutomaticReadingResult from '../AutomaticReadingResult';
import './AutomaticReadingItem.scss';

interface AutomaticReadingItemProps {
    diExtraction?: IDiExtraction;
}

/** @warnign This component is memoized!! (see export) */
const AutomaticReadingItem = (props: AutomaticReadingItemProps) => {
    const { diExtraction } = props;

    const [, analytics] = useAnalyticsStore();
    const [t] = useTranslation('automaticReading');
    const [, { rejectManyDiExtractions, approveManyDiExtractions }] = useDiExtraction();
    const { format, fromNow } = useDateFormatter();

    const [expanded, setExpanded] = useState(false);

    const s = DiExtrationStatus;
    const state = diExtraction?.state as DiExtrationStatus;

    const isExpandable = [s.VALIDATION_ERROR, s.PENDING_REVIEW].includes(state);
    const isExpanded = isExpandable && expanded;

    const modifiers = [
        isExpanded ? 'AutomaticReadingItem--expanded' : 'AutomaticReadingItem--collapsed',
        isExpandable ? 'AutomaticReadingItem--clickable' : '',
        !diExtraction ? 'AutomaticReadingItem--is-skeleton' : ''
    ].join(' ');

    const countWarnings = (type: 'LOW' | 'HIGH') => {
        const count = diExtraction?.warnings.filter((warning) => warning.severityLevel === type)?.length;
        return count || 0;
    };

    return (
        <div
            className={`AutomaticReadingItem ${modifiers}`}
            data-testid="AutomaticReadingItem"
            data-test-state={props.diExtraction?.state}
            data-test-warnings={
                (props.diExtraction?.warnings?.length || 0) > 0
                    ? props.diExtraction?.warnings.some((w) => w.severityLevel === 'HIGH')
                        ? 'HIGH'
                        : 'LOW'
                    : null
            }
            role="listitem"
        >
            <div className="AutomaticReadingItem__row" onClick={() => setExpanded(!expanded)}>
                <div className="AutomaticReadingItem__metadataRow">
                    <div className="AutomaticReadingItem__filename">{diExtraction?.fileName}</div>
                    <div className="AutomaticReadingItem__metadataContainer">
                        {diExtraction?.requestDate && (
                            <div className="AutomaticReadingItem__metadata">
                                {(diExtraction?.origin === 'EMAIL' && <img src={IconMailGrey16} />) ||
                                    (diExtraction?.origin === 'WEB' && <img src={IconCloudUploadGrey16} />) ||
                                    null}
                                <Tooltip
                                    msg={`${diExtraction?.origin} - ${format(diExtraction?.requestDate)}`}
                                    delayShow={500}
                                >
                                    {fromNow(diExtraction?.requestDate)}
                                </Tooltip>
                            </div>
                        )}
                        {diExtraction?.userMail && (
                            <div className="AutomaticReadingItem__metadata">
                                <img src={IconUserGrey16} />
                                <span>{diExtraction?.userMail}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="AutomaticReadingItem__resultsRow">
                    {diExtraction && <AutomaticReadingResult diExtraction={diExtraction} />}
                </div>
                <div className="AutomaticReadingItem__statusRow">
                    {(diExtraction?.state === 'PENDING_REVIEW' || diExtraction?.state === 'VALIDATION_ERROR') && (
                        <>
                            {countWarnings('HIGH') > 0 && (
                                <DiExtractionWarning
                                    type="HIGH"
                                    messages={[t('warnings.high', { ns: 'diExtraction' })]}
                                />
                            )}
                            {countWarnings('LOW') > 0 && (
                                <DiExtractionWarning
                                    type="LOW"
                                    messages={[t('warnings.low', { ns: 'diExtraction' })]}
                                />
                            )}
                        </>
                    )}
                    {diExtraction?.state && <AutomaticReadingStatus state={diExtraction.state} />}

                    <div className="AutomaticReadingItem__actions" onClick={(e) => e.stopPropagation()}>
                        <IconButton
                            ariaLabel={t('delete')}
                            tabIndex={-1}
                            titulo="delete"
                            isLoading={false}
                            icon={<IconX size={16} />}
                            disabled={false}
                            action={() => {
                                if (!diExtraction) return;
                                rejectManyDiExtractions({ ids: [diExtraction.id] });
                                analytics.event(AnalyticsEvents.AUTOMATIC_READING_INLINE_REJECTED);
                            }}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />

                        {diExtraction?.state === DiExtrationStatus.PENDING_REVIEW && (
                            <IconButton
                                ariaLabel={t('approve')}
                                tabIndex={-1}
                                titulo="delete"
                                isLoading={false}
                                icon={<IconCheck size={16} />}
                                disabled={false}
                                action={() => {
                                    if (!diExtraction) return;
                                    approveManyDiExtractions({ ids: [diExtraction.id] });
                                    analytics.event(AnalyticsEvents.AUTOMATIC_READING_INLINE_APPROVED);
                                }}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isExpanded && diExtraction && (
                <div className="AutomaticReadingItem__detail">
                    <WasteFlowProvider api={httpWasteFlowApi}>
                        <DiExtraction diExtraction={diExtraction} />
                    </WasteFlowProvider>
                </div>
            )}
        </div>
    );
};

export default memo(
    AutomaticReadingItem,
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
);
