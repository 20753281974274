export const RESPONSABILITY_MANAGER_COCIRCULAR_ID = 674301;
export const RESPONSABILITY_FIELD_COCIRCULAR_ID = 80253;

const ROLES = {
    ROLE_MAINTENANCE: {
        description: 'roles.maintenance',
        importance: 1
    },
    ROLE_WRITER: {
        description: 'roles.writer',
        importance: 2
    },
    ROLE_READER: {
        description: 'roles.reader',
        importance: 3
    },
    ROLE_WRITER_AUDIT: {
        description: 'roles.writerAudit',
        importance: 1
    },
    ROLE_ADMIN: {
        description: 'roles.admin',
        importance: 1
    }
};

export const getRoles = (authorities) => {
    const allAuthorities = [];
    authorities.forEach((element) => {
        allAuthorities.push(ROLES[element]);
    });
    return allAuthorities;
};

export const getTheMostImportantRole = (authorities) => {
    let importantRole = [];
    authorities.forEach((element) => {
        if (importantRole.length === 0) {
            importantRole = [element];
        } else if (importantRole.length > 0) {
            importantRole.forEach((role) => {
                if (element.importance < role.importance) {
                    importantRole = [element];
                } else if (element.importance === role.importance) {
                    importantRole = [...importantRole, element];
                }
            });
        }
    });
    return importantRole;
};
