import HomePage from '../pages/HomePage';

export const HOME_ROUTES = [
    {
        component: HomePage,
        path: '/home',
        requiredPermission: 'canView',
        section: 'enterprise',
        exact: false
    }
];
