import { logger } from 'logger';
import { PropsWithChildren } from 'react';

import LegacyAuthProvider from 'context/AuthProvider';
import MultiProvider from 'lib/MultiProvider';
import { AnalyticsProvider, multipleAnalyticsApi } from 'modules/analytics';
import { sessionAnalyticsStorage } from 'modules/analytics/infrastructure/storage/sessionAnalyticsStorage';
import { AuthProvider, httpAuthApi } from 'modules/auth';
import { sessionDeepLinkStorage } from 'modules/auth/infrastructure/deeplink/sessionDeepLinkStorage';
import { localTokenStorage } from 'modules/auth/infrastructure/token/localTokenStorage';
import { localstorageOnboardingApi } from 'modules/onboarding/infastructure/api/localstorageOnboardingApi';
import { OnboardingProvider } from 'modules/onboarding/infastructure/react/OnboardingContext';
import { OrphanDocumentsProvider, httpOrphanDocuments } from 'modules/orphan-documents';

const GlobalStore = (props: PropsWithChildren<{}>) => {
    return (
        <MultiProvider
            providers={[
                // Provides user session
                <AuthProvider
                    api={httpAuthApi}
                    tokenStorage={localTokenStorage}
                    deepLinkStorage={sessionDeepLinkStorage}
                />,
                // Provides promotion for legacy routes ()that aren't under /promotion/:id)
                <LegacyAuthProvider />,
                // Analytics provider
                <AnalyticsProvider api={multipleAnalyticsApi} storage={sessionAnalyticsStorage} />,
                // Provides orphan documents for menu count (walle)
                <OrphanDocumentsProvider api={httpOrphanDocuments} logger={logger} />,
                // Provides onboarding resources
                <OnboardingProvider api={localstorageOnboardingApi} />
            ]}
        >
            {props.children}
        </MultiProvider>
    );
};

export default GlobalStore;
