import fetcher from 'lib/fetcher';
import { downloadFile, JsonSchema } from 'modules/shared';

import { TraceabilitiesApi } from '../../domain/TraceabilitiesApi';
import { Ler, StakeholderInfo } from '../../domain/Traceability/Traceability';
import { TraceabilitySaverExtended } from '../../domain/Traceability/TraceabilitySaverExtended';
import { carrierAdapter } from './adapters/carrierAdapter';
import { sumaryTotalsAdapter } from './adapters/sumaryTotalsAdapter';
import { traceabilitiesCriteriaAdapter } from './adapters/traceabilitiesCriteriaAdapter';
import { traceabilityAdapter } from './adapters/traceabilityAdapter';
import { traceabilityToReuseAdapter } from './adapters/traceabilityToReuseAdapter';
import { wasteManagerAdapter } from './adapters/wasteManagerAdapter';

export const httpTraceabilitiesApi: TraceabilitiesApi = {
    async getTraceabilityDetail({ id, promotionId }) {
        const containerTraceabilityRequest = fetcher(`api/traceabilities?id.equals=${id}`).then(
            ({ data }) => data.content.traceabilities[0]
        );

        const documentsRequest = fetcher(
            `api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`
        ).then(({ data }) => ({
            di: data?.content?.documents?.find((doc) => doc.type.name === 'DI')
        }));

        const finalTraceabilityRequest = fetcher(`/api/pro-ges-cod-lers/final-traceability/${id}`).then(
            ({ data }) => data
        );

        const [data, { di }, finalTraceability] = await Promise.all([
            containerTraceabilityRequest,
            documentsRequest,
            finalTraceabilityRequest
        ]);

        if (!data) throw new Error('Traceability not found');

        const traceability = traceabilityAdapter({ data, di, finalTraceability });

        return traceability;
    },
    async save(traceability) {
        const traceabilityId = traceability.id;
        // POST REQUEST is not legacy, its new: you send both traceability info and DI blob in the same request
        // When doing a PUT, you have to create the traceability, create the doc and then link (associate) them
        const di = traceability.di
            ? {
                  id: traceability.di?.id,
                  name: traceability.di?.name,
                  bytes: traceability.di?.bytes
              }
            : null;

        const params = {
            ...(traceabilityId ? { id: traceabilityId } : {}),
            ppgclId: traceability.ppgcl?.id,
            carrierLerId: traceability.carrier?.carrierLers?.[0]?.id,
            date: traceability.date,
            weight: traceability.weight,
            cost: traceability.cost,
            volume: traceability.volume,
            numDi: traceability.numDi,
            observations: traceability.observations,
            vehiclePlate: traceability.vehiclePlate,
            // If valorization is null (unknknow) set as 1
            valorization: traceability.ppgcl?.valuationPercentage ?? 1,
            incomplete: traceability.incomplete,
            wasteRegisterId: traceability.wasteRegisterId || null,
            // Only POST request needs to send di bytes, PUT uses associate-traceability ATM
            ...(traceabilityId ? {} : { di }),
            // Extra data
            ...(Object.values(traceability?.extra || {}).length > 0 ? { extra: traceability.extra } : {})
        };

        const request = params.id ? fetcher.put : fetcher.post;

        const { data } = await request('api/traceabilities', params);

        return data;
    },
    async getSaverExtendedById({ id, promotionId }) {
        if (id === undefined) return;

        const [traceability, di] = await Promise.all([
            fetcher(`api/traceabilities/${id}`) //
                .then(({ data }) => data.content),
            fetcher(`api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`) //
                .then(({ data }) => data?.content?.documents?.find((doc) => doc.type.name === 'DI'))
        ]);

        const result: TraceabilitySaverExtended = {
            id: traceability.id,
            wasteRegisterId: traceability.wasteRegisterId,
            date: traceability.movementDate,
            numDi: traceability.numDi,
            weight: traceability.weight,
            cost: traceability.cost,
            volume: traceability.volume,
            observations: traceability.observations,
            incomplete: traceability.status === 'WARNING',
            vehiclePlate: traceability.carrier ? traceability.carrier?.vehiclePlate : null,
            carrier: traceability.carrier, // TODO: REVISAR ESTO
            ppgcl: {
                id: traceability.wasteFlowId,
                ler: {
                    id: traceability.lerId,
                    code: traceability.lerCode,
                    type: traceability.lerTypeName
                },
                manager: {
                    id: traceability.wasteManager.id,
                    name: traceability.wasteManager.name
                },
                carrier: traceability.carrier
                    ? {
                          name: traceability.carrier?.name
                      }
                    : null,
                treatment: {
                    code: traceability.valorizationCode,
                    description: traceability.valorizationDescription
                },
                valuationPercentage: traceability.valorizationPercent,
                cost: traceability.cost,
                volume: traceability.volume
            },
            extra: traceability.extra,
            di:
                di?.id !== undefined
                    ? {
                          id: di.id,
                          url: di.url || undefined
                      }
                    : null
        };

        return result;
    },
    async getDiByTraceabilityId({ id, promotionId }) {
        const request = await fetcher(
            `api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`
        ).then(({ data }) => ({
            di: data?.content?.documents?.find((doc) => doc.type.name === 'DI')
        }));

        return { url: request.di?.url || '' };
    },
    async getTraceabilities(criteria) {
        const params = traceabilitiesCriteriaAdapter(criteria);
        const { data, headers } = await fetcher.get('api/traceabilities', { params });

        const traceabilities = data.content.traceabilities;
        const count = parseInt(headers['x-total-count']);

        const mappedTraceabilities = traceabilities.map((data) => traceabilityAdapter({ data }));

        return { traceabilities: mappedTraceabilities, total: count };
    },
    async getLers(params) {
        const { data } = await fetcher(`api/lers/project/${params.promotionId}`, {
            params: {
                'codLer.contains': params.search || null,
                'typeLer.contains': params.search || null
            }
        });

        const lers: Partial<Ler>[] = data?.content?.map((entry) => ({ ...entry, kind: entry.lerKind })) || [];
        return lers;
    },
    async getLerTypes(params) {
        const { data } = await fetcher(`api/lers/project/${params.promotionId}/types`, {
            params: {
                'name.contains': params.search || null
            }
        });
        const lerTypes: Array<{ id: number; name: string }> = data?.content || [];
        return lerTypes;
    },
    async getWasteManagers(params) {
        const { data } = await fetcher('api/maintenance/promo-pro-ges-cod-lers', {
            params: {
                'promocionId.equals': params.promotionId,
                'gestoraName.contains': params.search || null,
                page: params.page || null
            }
        });

        const wasteManagers: StakeholderInfo[] = data.map(wasteManagerAdapter);

        return wasteManagers;
    },
    async getCarriers(params) {
        const { data } = await fetcher('api/maintenance/promo-pro-ges-cod-lers', {
            params: {
                'promocionId.equals': params.promotionId,
                'carrierName.contains': params.search || null,
                page: params.page || null
            }
        });

        const carriers: StakeholderInfo[] = data.map(carrierAdapter);

        return carriers;
    },
    async removeTraceability({ traceability, operation }) {
        const config: any = {
            message: {
                success: { text: `operations.${operation}.sucess`, ns: 'traceabilities' },
                error: { text: `operations.${operation}.error`, ns: 'traceabilities' }
            }
        };

        await fetcher.delete(`api/traceabilities/${traceability.id}`, config);
        return;
    },
    async getTraceabilitiesStats({ promotionId, ...criteria }) {
        const params = { ...traceabilitiesCriteriaAdapter(criteria), 'traceabilityStatus.in': 'COMPLETED,WARNING' };
        const kpisRequest = await fetcher(`api/traceability-metrics/promotion/${promotionId}/kpi`, { params });
        const stats = kpisRequest.data.content;

        return { stats };
    },
    async getTraceabilitiesSummaryByDate({ measure, unit, filters: { promotionId, ...filters } }) {
        const params = traceabilitiesCriteriaAdapter({ ...filters, status: ['COMPLETED', 'WARNING'] });
        const url = `api/traceability-metrics/promotion/${promotionId}/${measure}-by-month/unit/${unit}`;

        const summaryRequest = await fetcher(url, { params });
        const summary = summaryRequest.data.content;

        return summary;
    },
    async getTraceabilitiesSummaryTotals({ measure, filters: { promotionId, ...filters } }) {
        const params = traceabilitiesCriteriaAdapter({ ...filters, status: ['COMPLETED', 'WARNING'] });
        // the unit params has no effect in this, because table displays all values (but table and chart share same endpoint)

        const url = `api/traceability-metrics/promotion/${promotionId}/${measure}-summary/unit/movements`;
        const { data } = await fetcher(url, { params });
        const summary = sumaryTotalsAdapter({ data: data.content });

        return summary;
    },
    async downloadTraceabilities(criteria) {
        const { data } = await fetcher('api/traceabilities/csv', {
            params: traceabilitiesCriteriaAdapter({ ...criteria }),
            headers: { accept: 'text/csv' },
            responseType: 'blob'
        });

        await downloadFile(`Datos trazabilidad.csv`, data);
    },
    async getSaveExtraSchemaByPromotionId(payload) {
        const { data } = await fetcher.get(`api/traceabilities/promotion/${payload.promotionId}/schema`);
        const schema: JsonSchema = data.content?.schema;
        return schema;
    },
    async getReuseDetail({ id, promotionId }) {
        if (id === undefined) return;

        const containerTraceabilityRequest = fetcher(`api/traceabilities?id.equals=${id}`).then(
            ({ data }) => data.content.traceabilities[0]
        );

        const documentsRequest = fetcher(
            `api/documents/promotion/${promotionId}?trazabilidadContenedorId.equals=${id}`
        ).then(({ data }) => ({
            reuseDoc: data?.content?.documents?.find((doc) => doc.type.id === 640151)
        }));

        const [data, { reuseDoc }] = await Promise.all([containerTraceabilityRequest, documentsRequest]);

        if (!data) throw new Error('Reuse not found');

        const reuse = traceabilityToReuseAdapter({ data, reuseDoc });
        return reuse;
    },
    async saveReuse(newReuse) {
        if (newReuse.id === undefined) {
            const createMessage = {
                success: `Reutilización creada con éxito`,
                error: `Error al crear la reutilización`
            };

            const reuseForApi = { ...newReuse, lerId: newReuse?.ler?.id } as any;
            delete reuseForApi.ler;

            await fetcher.post('/api/self-reuse-traceability', reuseForApi, { message: createMessage } as any);
            return;
        }

        // ELSE, Reuse PUT doesn't exists ==> GET traceability and then PUT traceability with reuse modifications
        const traceability = await this.getSaverExtendedById({ id: newReuse.id, promotionId: newReuse.promotionId! });
        if (!traceability) return;

        this.save({
            ...traceability,
            date: newReuse.date,
            weight: newReuse.weight,
            volume: newReuse.volume,
            observations: newReuse.observations
        });
    }
};
