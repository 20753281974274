import { IconBusinessplan } from '@tabler/icons';
import { usePromotion } from 'modules/promotion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation } from 'react-router-dom';
import costsServices from '../../api/costs/costs.services';
import HasPermission from '../../components/AccessCheckers/HasPermission';
import Buscador from '../../components/BasicComponents/Buscador/Buscador';
import CreateButton from '../../components/BasicComponents/Buttons/CreateButton';
import SearchButton from '../../components/BasicComponents/Buttons/SearchButton';
import SubmitButton from '../../components/BasicComponents/Buttons/SubmitButton';
import InputFecha from '../../components/BasicComponents/Inputs/InputFecha';
import Message from '../../components/BasicComponents/Messages/Message';
import CodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import SortMenu from '../../components/BasicComponents/Sort/SortMenu';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import { TituloH1, TituloPromocion } from '../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../context/AuthProvider';
import { createFilterWithObject } from '../../utils/constants/activities.constants';
import { getObjFilter } from '../../utils/constants/costs.constants';
import { INITIAL_PAGE } from '../../utils/constants/pagination.constants';
import {
    createFilterWithObject as createFilterWithObjectOld,
    currencyFormatDE,
    roundNumber
} from '../../utils/helpers/general.helpers.js';
import Layout from '../Layout/Layout';
import CostResult from './CostResult';
import './Costes.scss';

const ListadoCostes = (props) => {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [results, setResults] = useState([]);
    const [scroll, setScroll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [costeTotal, setCosteTotal] = useState(0);
    const [pagination, setPagination] = useState(0);
    const [counter, setCounter] = useState(0);
    const [{ promotion }] = usePromotion();

    if (promotion !== null && promotion?.permissions?.costs) {
        history.goBack();
    }

    const [values, setValues] = useState({
        empresa: '',
        promocion: '',
        fechaDesde: '',
        fechaHasta: '',
        gestora: '',
        codLer: ''
    });

    const [selectedOption, setSelectedOption] = useState();
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (props.promocion?.id) {
                let valuesAux = values;
                if (location.state?.values) {
                    valuesAux = location.state?.values;
                    setValues(valuesAux);
                }

                getCostes(INITIAL_PAGE, valuesAux, true);
                getTotalCostes();
            }
        }
        return () => {
            mounted = false;
        };
    }, [props.promocion?.id]);

    const getFilter = (page, formValues) => {
        let filter;
        filter = formValues?.sortOption ? `?page=${page}` : `?page=${page}&sort=fecha,desc&sort=id,desc`;
        const objFilter = getObjFilter(values);
        const addFilter = createFilterWithObject(formValues, objFilter);
        return (filter += addFilter);
    };

    const countNumberCosts = async (formValues) => {
        let filter = `?`;
        const objFilter = getObjFilter(formValues);
        const addFilter = createFilterWithObject(formValues, objFilter);
        filter = filter += addFilter;
        const counterCosts = await costsServices.countCosts(props.promocion.id, filter);
        setCounter(counterCosts);
    };

    const getCostes = async (page, formValues, newSearch = false) => {
        if (props.promocion?.id) {
            const filter = getFilter(page, formValues);
            const costes = await costsServices.getCosts(props.promocion.id, filter);
            countNumberCosts(formValues);
            if (costes !== undefined && !newSearch) {
                setResults(results.concat(costes));
            } else {
                setResults(costes);
            }
            if ((costes === undefined || costes.length === 0) && newSearch) {
                setMessage(t('noResults', { ns: 'common' }));
            } else {
                setMessage('');
            }
        }
    };

    const totalCosts = {
        fechaDesde: `&fecha.greaterThanOrEqual=${values.fechaDesde}`,
        fechaHasta: `&fecha.lessThanOrEqual=${values.fechaHasta}`,
        codLer: `&codLerId.equals=${values.codLer?.id}`,
        gestora: `&gestoraId.equals=${values.gestora?.id}`
    };

    const getFilterTotalCosts = () => {
        let filter = `?`;
        const addFilter = createFilterWithObjectOld(values, totalCosts);
        return (filter += addFilter);
    };

    const getTotalCostes = async () => {
        let total = 0;
        const filter = getFilterTotalCosts();
        const results = await costsServices.getTotalCostsPromotion(props.promocion.id, filter);
        if (results !== undefined) {
            total = results;
        }
        setCosteTotal(Math.round(total));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setPagination(INITIAL_PAGE);
        getCostes(INITIAL_PAGE, values, true);
        getTotalCostes();
        setOpenMenu(false);
        setSelectedOption(values?.sortOption);
        setLoading(false);
    };

    const handleLoadMore = async () => {
        if (!scroll) {
            setScroll(true);
            setPagination(pagination + 1);
            getCostes(pagination + 1, values);
            setScroll(false);
        }
    };

    const onClickEliminar = async (id) => {
        await costsServices.deleteCost(id);
        const newResults = results.filter((res) => {
            return res.id !== id;
        });
        setResults(newResults);
        setCounter(counter - 1);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    //SORT

    const deleteFilter = async () => {
        setSelectedOption();
        setValues({ ...values, sortOption: '' });
        setLoading(true);
        setPagination(INITIAL_PAGE);
        const filter = getFilter(INITIAL_PAGE, { ...values, sortOption: '' });
        const costs = await costsServices.getCosts(props.promocion.id, filter);
        setResults(costs);
        setLoading(false);
    };

    const onChangeRadio = (option) => {
        setValues({ ...values, sortOption: option });
    };

    const isChecked = (option) => {
        return values?.sortOption?.label === option.label ? true : false;
    };

    const { fechaDesde, fechaHasta } = values;

    return (
        <Layout>
            <div className="create-button">
                <TituloH1 titulo={t('title', { ns: 'costs' })} />
                <HasPermission ifRole="canCreate" section="costs">
                    <CreateButton
                        text={t('new', { ns: 'costs' })}
                        to="/costs/create"
                        state={{ results: results, values: values, page: pagination }}
                    />
                </HasPermission>
            </div>
            <Buscador titulo={t('search', { ns: 'costs' })}>
                <form onSubmit={handleFormSubmit}>
                    <PromoProGesCodLerAutocomplete {...{ values, setValues }} value={values?.gestora} />
                    <CodLerAutocomplete {...{ values, setValues }} value={values?.codLer} name="codLer" />
                    <InputFecha
                        label={t('date.dateFrom', { ns: 'common' })}
                        id="fechaDesde-trazabilidad"
                        name="fechaDesde"
                        value={fechaDesde}
                        onChange={handleInputChange}
                        values={values}
                        setValues={setValues}
                        notLimit={true}
                        aspaLimpiar
                    />
                    <InputFecha
                        label={t('date.dateTo', { ns: 'common' })}
                        id="fechaHasta-trazabilidad"
                        name="fechaHasta"
                        value={fechaHasta}
                        onChange={handleInputChange}
                        values={values}
                        setValues={setValues}
                        notLimit={true}
                        aspaLimpiar
                    />
                    <SearchButton text={t('search.search', { ns: 'common' })} />
                </form>
            </Buscador>
            {loading && <Spinner />}
            <TituloPromocion titulo={t('metrics', { ns: 'common' })} />
            <div className="metricas card">
                <div className="metricas__icon">
                    <IconBusinessplan size={36} stroke={1.25} color={'#41624c'} />
                </div>
                <div>
                    <span>{t('generic', { ns: 'costs' })}</span>
                    <p>{currencyFormatDE(roundNumber(costeTotal))} €</p>
                </div>
            </div>
            <TituloPromocion titulo={`${t('search.results', { ns: 'common' })} (${counter})`} />
            <SortMenu
                openMenu={openMenu}
                setOpenMenu={setOpenMenu}
                selectedOption={selectedOption}
                deleteFilter={deleteFilter}
            >
                <form onSubmit={handleFormSubmit} className="sort__form">
                    {getObjFilter()
                        .filter((oneFilter) => oneFilter.typeOf === 'SORT')
                        .map((input) => (
                            <div key={input.label} className="sort__inputContainer">
                                <input
                                    className="sort__input"
                                    type="radio"
                                    value={input.label}
                                    name={input.name}
                                    onChange={() => onChangeRadio(input)}
                                    checked={isChecked(input)}
                                    id={input.label}
                                />
                                <label className="sort__label" htmlFor={input.label}>
                                    {t(input.label, { ns: 'sort' })}
                                </label>
                            </div>
                        ))}
                    <SubmitButton text={t('results', { ns: 'common' })} />
                </form>
            </SortMenu>
            {results !== undefined && results.length > 0 && (
                <>
                    <InfiniteScroll
                        dataLength={results.length}
                        next={() => handleLoadMore()}
                        hasMore={true}
                        style={{ width: '100%' }}
                        scrollableTarget="main-layout"
                    >
                        {results.map((result) => (
                            <CostResult
                                cost={result}
                                key={result.id}
                                values={values}
                                deleteFunction={onClickEliminar}
                            />
                        ))}
                    </InfiniteScroll>
                </>
            )}

            {message.length > 0 && <Message message={message} />}
        </Layout>
    );
};
export default withAuth(ListadoCostes);
