import { PromotionStatus } from './PromotionPreview';

type OptionalSearchFor = 'province' | 'mainEnterpriseName' | 'type' | 'num';
export interface PromotionPreviewCriteria {
    id?: number;
    page?: number;
    status?: PromotionStatus;
    search?: string;
    searchFor?: Array<OptionalSearchFor> | [];
    // to pin projects
    pinnedPreviewIds?: Array<number>;
}

export const arrayOptionalSearchFor: Array<OptionalSearchFor> = ['province', 'mainEnterpriseName', 'type', 'num'];
