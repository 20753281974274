import { IconUpload } from '@tabler/icons';
import { forwardRef, HTMLAttributes, useEffect, useState } from 'react';

import DragNDrop from 'components/BasicComponents/DragAndDrop/DragNDrop';
import { IconInnerShadowTopRight } from 'icons';
import { DocumentTypes } from 'modules/document/domain/DocumentTypes';
import { useDocuments } from 'modules/document/infrastructure/react/DocumentContext';
import { usePromotion } from 'modules/promotion';
import { FileBase64 } from '../../../modules/document/domain/FileBase64';
import './DocumentPreview.scss';

export interface DocumentPreviewProps extends HTMLAttributes<HTMLDivElement> {
    documentId?: string | number | null;
    documentName?: string;
    traceabilityId?: string | number;
    onDocumentChange?: (document: { id?: number; name: string; bytes: string }) => void;
    onDocumentRemove?: () => void;
    documentType?: { id: number; name: string };
    persistDocument: boolean;
}

const DocumentPreview = forwardRef<HTMLDivElement, DocumentPreviewProps>((props, ref) => {
    const { onDocumentChange, onDocumentRemove, ...rest } = props;
    const documentType = props.documentType || DocumentTypes.DI;

    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id || NaN;

    const [documentId, setDocumentId] = useState(rest.documentId);
    const { data: document, loading } = useDocuments.findById({ promotionId, id: documentId });
    const upload = useDocuments.upload();
    const remove = useDocuments.remove();

    const [files, setFiles] = useState<FileBase64[]>([]);

    const updateFiles = async (payload: FileBase64[] | ((prev: FileBase64[]) => FileBase64[])) => {
        if (!promotion) return;
        let files: FileBase64[] = [];

        if (Array.isArray(payload)) files = payload;
        if (typeof payload === 'function') files = payload([]);

        setFiles(files);

        const file = files[0];
        if (file) {
            if (props.persistDocument) {
                const doc = await upload.mutate({
                    file,
                    promotionId,
                    type: documentType,
                    links: { traceabilityId: props.traceabilityId }
                });

                const id = doc.data?.id;
                setDocumentId(id);
                onDocumentChange?.({ id, name: file.name, bytes: file.bytes });
                return;
            }
            // else
            onDocumentChange?.({ id: undefined, name: file.name, bytes: file.bytes });
        } else {
            if (!documentId) return;

            if (props.persistDocument) {
                remove.mutate({ id: documentId });
            }

            setDocumentId(null);
            onDocumentRemove?.();
        }
    };

    useEffect(() => {
        setDocumentId(props.documentId);
        if (!props.documentId && !props.documentName) setFiles([]);
    }, [props.documentId, props.documentName]);

    const filesToShow =
        (files.length > 0 && files) ||
        // A virtual file to show the document if it is already uploaded
        (document && [{ bytes: '', name: document.name, type: document.mimeType || '' }]) ||
        [];

    const isLoading = [loading, upload.loading, remove.loading].some((l) => l === 'pending');

    const hasDocument = documentId && document?.id;

    const modifiers = [
        rest.className || '',
        isLoading ? 'DocumentPreview--is-loading' : '',
        hasDocument ? 'DocumentPreview--has-file' : ''
    ];

    return (
        <div ref={ref} {...rest} className={`DocumentPreview ${modifiers.join(' ')}`}>
            {hasDocument && <div className="DocumentPreview__preview">{<iframe src={document.url}></iframe>}</div>}

            {!isLoading && (
                <DragNDrop
                    label={' '}
                    className="DocumentPreview__dragAndDrop"
                    files={filesToShow}
                    setFiles={updateFiles}
                    confirmBeforeDelete={props.persistDocument}
                    maxFiles={1}
                    format={'base64'}
                    urlProp="url"
                    fileNameProp="name"
                    maxSizeMb={50}
                    fileProp="bytes"
                    contentTypeProp="type"
                    accept={['application/pdf', 'text/xml']}
                    // Just to ignore optional fields
                    {...({} as any)}
                >
                    {filesToShow.length === 0 && (
                        <>
                            <IconUpload />
                            <p className="DocumentPreview__title">
                                Elige {documentType.name.toLowerCase()} o arrástralos aquí
                            </p>
                            <p className="DocumentPreview__subtitle">
                                Los archivos deben tener formato PDF o XML (E3L)
                            </p>
                        </>
                    )}
                </DragNDrop>
            )}

            {isLoading && (
                <div className="DocumentPreview__spinner">
                    <IconInnerShadowTopRight />
                </div>
            )}
        </div>
    );
});

DocumentPreview.displayName = 'DocumentPreview';

export default DocumentPreview;
