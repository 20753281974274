import { IconBuildingFactory, IconEdit, IconLicense, IconLink, IconTrash, IconTruck } from '@tabler/icons';
import EllipsisText from 'components/BasicComponents/Text/EllipsisText';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import LerAlert from 'components/ComplexComponents/LerAlert';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import { withAuth } from '../../../../context/AuthProvider';
import IconButton from '../../../BasicComponents/Buttons/Small/IconButton';
import ModifyWasteHandling from './ModifyWasteHandling';
import './ResultPpgcl.scss';

const ResultPpgcl = ({
    ppgcl,
    toggleEdit,
    deletePpgcl,
    selectedPpgcl,
    setSelectedPpgcl,
    hasTreatmentContract,
    ...props
}) => {
    const transferOperatorName = {
        1: props?.promocion?.nombre,
        3: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.gestora?.empresa,
        4: ppgcl?.transferOperatorProGesCodLer?.procesoGestora?.gestora?.empresa
    };

    const changeSelectedPpgcl = () => {
        if (!ppgcl) return;

        setSelectedPpgcl(ppgcl);

        setTimeout(() => {
            const ppgclHtml = document.getElementById(ppgcl.id);

            ppgclHtml.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });

            ppgclHtml.style.animation = 'blinkEffect 1s linear';
        }, 100);
    };

    return (
        <div
            id={ppgcl.id}
            className={`ppgcl ${selectedPpgcl ? 'ppgcl-openedDoc' : ''}  ${
                selectedPpgcl?.id === ppgcl.id ? 'ppgcl-selected' : ''
            }`}
        >
            <div className="ppgcl__content">
                <div className="ppgcl__ler-container">
                    <p className="ppgcl__ler">
                        {ppgcl.wasteManagerProGesCodLer.codLER.codigoLER} -{' '}
                        {ppgcl.wasteManagerProGesCodLer.procesoGestora.valorizacion.codigoValorizacion} -{' '}
                        {ppgcl.wasteManagerProGesCodLer.actualValorizationPercentage === 1
                            ? '% Desc.'
                            : currencyFormatDE(ppgcl.wasteManagerProGesCodLer.actualValorizationPercentage) + '%'}
                    </p>
                    <LerAlert code={ppgcl.wasteManagerProGesCodLer.codLER.codigoLER} />
                </div>
                <div className="ppgcl__agents">
                    <div className="ppgcl__agent">
                        <IconBuildingFactory stroke={1.4} size={20} />
                        <EllipsisText>
                            {ppgcl.wasteManagerProGesCodLer.procesoGestora.gestora.empresa || '-'}
                        </EllipsisText>
                    </div>
                    <div className="ppgcl__agent">
                        <IconTruck stroke={1.4} size={20} />
                        <EllipsisText>
                            {ppgcl.carrierProGesCodLer?.procesoGestora?.gestora?.empresa || '-'}
                        </EllipsisText>
                    </div>
                    <div className="ppgcl__agent">
                        <IconLicense stroke={1.4} size={20} />
                        <EllipsisText>{transferOperatorName[ppgcl?.transferOperatorType?.id] || '-'}</EllipsisText>
                    </div>
                </div>
                <div className="ppgcl__containerButtons">
                    <Tooltip msg={hasTreatmentContract ? 'Cambiar CT vinculado' : 'Asociar Contrato de Tratamiento'}>
                        <div className={!hasTreatmentContract ? 'ppgcl__purpleAssociate' : ''}>
                            <IconButton action={() => changeSelectedPpgcl()} icon={<IconLink />} />
                        </div>
                    </Tooltip>
                    <IconButton action={() => toggleEdit(ppgcl)} icon={<IconEdit />} />
                    <ModifyWasteHandling
                        wasteFlowId={ppgcl?.id}
                        wasteHandling={{
                            id: ppgcl?.wasteManagerProGesCodLer?.id,
                            ler: {
                                code: ppgcl?.wasteManagerProGesCodLer?.codLER?.codigoLER,
                                description: ppgcl?.wasteManagerProGesCodLer?.codLER?.descripcion,
                                id: ppgcl?.wasteManagerProGesCodLer?.codLER?.id
                            },
                            treatment: {
                                code: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion?.codigoValorizacion,
                                description: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion?.descripcion,
                                id: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion?.id
                            },
                            manager: {
                                id: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.gestora?.id
                            }
                        }}
                        onSaved={() => window.location.reload()}
                    />
                    <IconButton action={() => deletePpgcl(ppgcl)} icon={<IconTrash />} />
                </div>
            </div>
            {ppgcl?.message !== undefined && <div className="ppgcl__message">{ppgcl?.message}</div>}
        </div>
    );
};

export default withAuth(ResultPpgcl);
