import fetcher from '../../../lib/fetcher';

export const getTraceabilities = async (filter = '') => {
    const URL = `/api/tracing/container-traceability${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getTraceabilitiesCount = async (filter = '') => {
    const URL = `api/traceabilities/count${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
    }
};
