import Compressor from 'compressorjs';

const MAX_FILE_SIZE = 3500000; // 3.5MB
const MIN_FILE_SIZE = 1000000; // 1MB

export const compressFile = (file, fileSize) => {
    return new Promise((resolve) => {
        new Compressor(file, {
            // 0 = hight compression (horrible quality), 1 = no compression at all (good quality)
            quality: fileSize >= MAX_FILE_SIZE ? 0.5 : fileSize <= MIN_FILE_SIZE ? 0.9 : 0.7,
            // If the file size >= convertSize && file type is in convertTypes array, the image will be converted to JPEG
            // We do it like this because, for instance, PNGs are not being compressed enought (idk why)
            convertSize: MAX_FILE_SIZE,
            convertTypes: ['image/png', 'image/webp'],

            success(result) {
                resolve({ processedFile: result, contentType: result?.type, fileName: result?.name });
            },
            error() {
                // Returning the original file if it fails (p.e: when trying to compress a PDF (only images allowed))
                resolve({ processedFile: file, contentType: undefined, fileName: undefined });
            }
        });
    });
};

export const blobToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
        reader.onerror = (error) => {
            reader.abort();
            reject(console.error(error));
        };

        reader.onload = (e) => {
            resolve(e?.target?.result?.split(';base64,')[1]);
        };
    });
};

export const openFile = async (file, contentType) => {
    const fileURL = `data:${contentType};base64,${file}`;
    const win = window.open();

    let htmlElement = `<iframe src="${fileURL}" frameborder="0" style="border:none; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen />`;

    // Doing this bc Chrome doesn't display big images with iframe for some reason
    if (contentType.includes('image')) {
        htmlElement = `<img src="${fileURL}" style="max-width:100vw; max-height:100vh;" />`;
    }

    win.document.write(htmlElement);
};

export const openFileByURL = (preSignedUrl) => {
    window.open(preSignedUrl, '_blank');
};
