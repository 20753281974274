import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import GoBack from 'components/BasicComponents/Buttons/GoBack';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import DocumentPreview from 'components/ComplexComponents/DocumentPreview';
import { DocumentTypes } from 'modules/document/domain/DocumentTypes';
import { ReuseSaver } from 'modules/traceabilities/domain/Reuse/ReuseSaver';
import Layout from 'pages/Layout/Layout';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useReuseForm } from '../hooks/useReuseForm';
import './NewReuse.scss';
import ReuseForm from './ReuseForm';

const NewReuse = () => {
    const [t] = useTranslation('reuse');
    const history = useHistory();
    const { reuseId } = useParams<{ reuseId: string }>();
    const { isPending, error, reuse, save, setReuse } = useReuseForm({ reuseId });

    const mode = reuseId === undefined ? 'create' : 'edit';

    const modifiers = [error ? 'NewReuse__main--has-error' : '', isPending ? 'NewReuse__main--is-loading' : ''];

    return (
        <div className="NewReuse__layoutWrapper">
            <Layout>
                <div className="NewReuse">
                    <header className="NewReuse__header">
                        <div>
                            <GoBack goBack={() => history.goBack()} />
                            <TituloH1 titulo={mode === 'create' ? t('newReuse') : `${t('editReuse')} #${reuseId}`} />
                        </div>
                    </header>

                    <SimpleCard>
                        <div className={`NewReuse__main ${modifiers.join(' ')}`} data-testid="NewReuse">
                            {error && <ErrorCard className="card__alert-error NewReuse__errorMsg" error={error} />}

                            <div className="NewReuse__content">
                                <DocumentPreview
                                    persistDocument={mode === 'edit'} // only upload when edit mode
                                    documentType={DocumentTypes.reuseCertificate}
                                    className="NewReuse__document"
                                    documentId={reuse.reuseDoc?.id}
                                    documentName={reuse?.reuseDoc?.name} // To reset files after traceability POST
                                    traceabilityId={reuseId}
                                    onDocumentChange={(doc) => {
                                        const reuseDoc = doc.id || doc.bytes ? (doc as ReuseSaver['reuseDoc']) : null;
                                        setReuse({ ...reuse, reuseDoc });
                                    }}
                                />

                                <div className="NewReuse__form">
                                    <ReuseForm
                                        key={JSON.stringify(reuse?.id || 'new')} // Forces form to dismount when traceability changes
                                        initialReuseData={reuse}
                                        error={error}
                                        onChange={(form) => {
                                            setReuse({ ...reuse, ...form });
                                        }}
                                    />
                                </div>
                            </div>

                            <footer className="NewReuse__footer">
                                <div style={{ marginLeft: 'auto' }} />
                                <CreateButton
                                    isLoading={isPending}
                                    disabled={isPending}
                                    extraClasses="NewReuse__save"
                                    type="button"
                                    icon={null}
                                    text="Guardar"
                                    action={save}
                                />
                            </footer>
                        </div>
                    </SimpleCard>
                </div>
            </Layout>
        </div>
    );
};

export default NewReuse;
