import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import SemiCircularIndicator from 'components/BasicComponents/Progress/SemiCircularIndicator/SemiCircularIndicator';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import { usePromoProtocolAndExecution } from 'modules/comparative';
import { getBooleanStatus, getPercentageStatus, getScoreStatus } from 'modules/comparative/domain/comparative.helpers';
import { usePromotion } from 'modules/promotion';
import { useTranslation } from 'react-i18next';
import './ProtocolAndExecution.scss';

const ProtocolAndExecution = () => {
    const [t] = useTranslation('comparative');
    const [{ promotion }] = usePromotion();

    const { data, loading } = usePromoProtocolAndExecution(promotion?.id || NaN);

    const Label = (name: string, value: number | boolean | null | undefined, niceToHave?: boolean) => {
        if (value === undefined) return '';

        const isBoolean = typeof value === 'boolean';
        const status = isBoolean ? getBooleanStatus(value, niceToHave) : getPercentageStatus(value!);

        return (
            <>
                <span className={`ProtocolAndExecution__status --${status}`}>
                    {(!isBoolean && `${value}%`) || <KPIStatusIcon status={status} />}
                </span>
                <span>{t(name)}</span>
            </>
        );
    };

    return (
        <div className={`ProtocolAndExecution ${loading === 'pending' ? '--skeleton' : ''}`}>
            <SimpleCard>
                <section className="ProtocolAndExecution__section">
                    <div
                        className={`ProtocolAndExecution__indicatorWrapper 
                                --${getScoreStatus(data?.protocol.score || 0)}`}
                    >
                        <SemiCircularIndicator isPercentage={false} maxValue={10} value={data?.protocol.score}>
                            Protocolo
                        </SemiCircularIndicator>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Adecuación zonas" />
                        <p>{Label('valorizable', data?.protocol.areas.valorizables)}</p>
                        <p>{Label('dangerous', data?.protocol.areas.dangerous)}</p>
                        <p>{Label('rsu', data?.protocol.areas.rsu)}</p>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Cartelería" />
                        <p>{Label('valorizable', data?.protocol.signage.valorizables)}</p>
                        <p>{Label('dangerous', data?.protocol.signage.dangerous)}</p>
                        <p>{Label('rsu', data?.protocol.signage.rsu)}</p>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Contratos de tratamiento" />
                        <p>{Label('valorizable', data?.protocol.treatmentContracts.valorizables)}</p>
                        <p>{Label('dangerous', data?.protocol.treatmentContracts.dangerous)}</p>
                        <p>{Label('rsu', data?.protocol.treatmentContracts.rsu, true)}</p>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Otros" />
                        <p>{Label('protocol.existPgr', data?.protocol.existPgr)}</p>
                    </div>
                </section>
            </SimpleCard>
            <SimpleCard>
                <section className="ProtocolAndExecution__section">
                    <div
                        className={`ProtocolAndExecution__indicatorWrapper 
                                --${getScoreStatus(data?.execution.score || 0)}`}
                    >
                        <SemiCircularIndicator isPercentage={false} maxValue={10} value={data?.execution.score}>
                            Ejecución
                        </SemiCircularIndicator>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Pureza" />
                        <p>{Label('valorizable', data?.execution.valorizables.purity)}</p>
                        <p>{Label('dangerous', data?.execution.dangerous.purity)}</p>
                        <p>{Label('rsu', data?.execution.rsu.purity)}</p>
                    </div>
                    <div className="ProtocolAndExecution__group">
                        <TituloH3 titulo="Adecuación punto limpio" />
                        <p>{Label('waterproofTrays', data?.execution.dangerous.waterproofTrays)}</p>
                        <p>{Label('rainProtection', data?.execution.dangerous.rainProtection)}</p>
                    </div>
                </section>
            </SimpleCard>
        </div>
    );
};

export default ProtocolAndExecution;
