import { createFilterWithObject } from '../../../utils/helpers/general.helpers';
import { getTraceabilities, getTraceabilitiesCount } from '../../traceability/api/getTraceabilities';

export const searchTraceabilitiesDocument = async (DOCUMENT, page) => {
    const filter = `?documentoPromoId.equals=${DOCUMENT.id}&page=${page}`;
    let traceabilities = await getTraceabilities(filter);
    traceabilities = traceabilities?.data.content.traceabilities;
    return traceabilities;
};

export const countTraceabilitiesDocument = async (DOCUMENT) => {
    const filter = `?documentoPromoId.equals=${DOCUMENT.id}`;
    const countTraceabilitiesDocument = await getTraceabilitiesCount(filter);
    return countTraceabilitiesDocument.data;
};

const _objFilter = (values) => {
    return {
        codLer: `&codLer.equals=${values.codLer?.codigoLER}`,
        fechaDesde: `&fechaMovimiento.greaterThanOrEqual=${values.fechaDesde}`,
        fechaHasta: `&fechaMovimiento.lessThanOrEqual=${values.fechaHasta}`,
        gestora: `&gestoraId.equals=${values.gestora?.id}`,
        transportista: `&carrierId.in=${values.transportista?.map((carrier) => carrier.id)}`
    };
};

export const filter = (page, values, DOCUMENT, PROMOTION) => {
    const typeOfDocument =
        DOCUMENT.type.id === 86153 ? `&hasInvoice.specified=FALSE` : `&hasCertificate.specified=FALSE`;
    let filter = `?page=${page}&promocionId.in=${PROMOTION.id}&sort=fechaMovimiento,desc&sort=id,desc${typeOfDocument}&traceabilityStatus.in=COMPLETED,WARNING`;
    const addFilter = createFilterWithObject(values, _objFilter(values));
    return (filter += addFilter);
};

export const searchTraceabilitiesWithoutDocument = async (filter) => {
    const traceabilities = await getTraceabilities(filter);
    const traceabilitiesWithoutDocument = traceabilities.data.content.traceabilities;
    return traceabilitiesWithoutDocument;
};

// prepara el objeto para hacer el put a disassociate-traceability
export const createObjectDisassociate = (DOCUMENT, arrayTraceabilities) => {
    const arrayIds = arrayTraceabilities.map((traceability) => traceability.id);
    return {
        documentId: DOCUMENT.id,
        traceabilityIds: arrayIds
    };
};
