export const customStyles = (inputVacio, color) => {
    return {
        control: (styles) => ({
            ...styles,
            backgroundColor: color ? color : '#f8f7f7',
            fontFamily: 'Be Vietnam Pro',
            fontSize: '14px',
            color: '#333333',
            height: '44px',
            width: '100%',
            marginRight: '10px',
            border: 0,
            boxShadow:
                inputVacio === true &&
                'rgba(250, 128, 114, 0.8) 0px 0px 5px 0px, rgba(250, 128, 114, 0.8) 0px 0px 1px 0px',
            borderRadius: '1rem'
        }),
        menu: () => ({
            color: '#333333',
            fontFamily: 'Be Vietnam Pro',
            fontSize: '14px',
            position: 'absolute',
            width: '100%',
            zIndex: 2,
            backgroundColor: 'white',
            borderRadius: '1rem',
            marginTop: '0.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            padding: '0.3rem 0rem'
        }),
        menuList: (styles) => ({
            ...styles,
            padding: '0.3rem 0.6rem'
        }),
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? 'hsl(240,0%,98%)' : 'white',
                color: '#333333',
                fontSize: '1.4rem',
                width: '100%',
                borderRadius: '0.75rem',
                fontWeight: 400,
                lineHeight: '2rem',
                cursor: 'pointer'
            };
        },
        placeholder: (styles) => ({
            ...styles,
            color: 'hsl(223, 3%, 50%)',
            position: 'static',
            transform: 'none'
        }),
        singleValue: (provided) => {
            return {
                ...provided,
                color: '#273749',
                fontSize: '14px'
            };
        }
    };
};

export const iconSelect = () => {
    return {
        container: (styles) => ({
            ...styles,
            width: '40rem'
        }),
        control: () => ({
            border: '1px solid 	#dbdbff',
            backgroundColor: '#E8E8FF',
            display: 'flex',
            flexDirection: 'row',
            borderRadius: '1rem',
            width: '9rem'
        }),

        menu: () => ({
            color: '#333333',
            fontFamily: 'Be Vietnam Pro',
            fontSize: '14px',
            position: 'absolute',
            width: '100%',
            zIndex: 2,
            backgroundColor: 'white',
            borderRadius: '1rem',
            marginTop: '0.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            padding: '0.3rem 0rem'
        }),
        menuList: (styles) => ({
            ...styles,
            padding: '0.3rem 0.6rem'
        }),
        option: (styles, { isFocused }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? 'hsl(240,100%,95%)' : 'white',
                color: '#333333',
                fontSize: '1.4rem',
                width: '100%',
                borderRadius: '0.75rem',
                fontWeight: 400,
                lineHeight: '2rem',
                cursor: 'pointer'
            };
        },
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                color: '#273749'
            };
        },
        singleValue: (provided) => {
            return {
                ...provided,
                color: '#273749',
                fontSize: '14px'
            };
        }
    };
};

export const hasMore = (results) => (results.length === 20 ? true : false);

export const getCarrierIds = (carrier) => {
    if (Array.isArray(carrier)) {
        return carrier.map((c) => c.id).join(',');
    }

    if (typeof carrier === 'object' && carrier !== null) {
        // eslint-disable-next-line no-unused-expressions
        return carrier.id;
    }

    return '';
};
