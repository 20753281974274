import usePrevious from 'hooks/usePrevious';
import { PropsWithChildren, useEffect, useState } from 'react';

import { IconInnerShadowTopRight } from 'icons';
import { Carrier } from 'modules/carrier';
import { Ler } from 'modules/ler';
import { WasteFlow } from 'modules/waste-flow';
import { WasteManager } from 'modules/waste-manager';
import CarrierSelector from './components/CarrierSelector';
import LerSelector from './components/LerSelector';
import WasteFlowInfo from './components/WasteFlowInfo';
import WasteManagerSelector from './components/WasteManagerSelector';
import { useWasteFlowSelector } from './hooks/useWasteFlow';
import './WasteFlowCarrierSelector.scss';

export type WasteFlowCarrierSelectorProps = PropsWithChildren<{
    promotionId: number;
    flow?: WasteFlow | null;
    carrier?: Carrier | null;
    onFlowChange?: (flow?: WasteFlow | null) => void;
    onCarrierChange?: (carrier?: Carrier | null) => void;
}>;

const WasteFlowCarrierSelector = (props: WasteFlowCarrierSelectorProps) => {
    const { promotionId } = props;
    const isMounted = usePrevious(true);

    const [ler, setLer] = useState<Ler | undefined>(props.flow?.ler);
    const [manager, setManager] = useState<WasteManager | undefined>(props.flow?.manager);
    const [carrier, setCarrier] = useState<Carrier | undefined | null>(props?.carrier);
    const { flow, setFlow, loading } = useWasteFlowSelector({ flow: props.flow, promotionId, ler, manager });

    // Update the flow when the props change
    useEffect(() => {
        setFlow(props.flow || undefined);

        // If flow was reset (for example, after creating a new traceability) we reset the 2 inputs
        if (flow?.id !== undefined && props.flow?.id === undefined) {
            setLer(props.flow?.ler);
            setManager(props.flow?.manager);
        }
    }, [JSON.stringify(props.flow)]);

    // Update the carrier when the props change (reset carrier)
    useEffect(() => {
        setCarrier(props.carrier || undefined);
    }, [JSON.stringify(props.carrier)]);

    // Update the ler and manager when the flow changes and fires the onFlowChange event
    useEffect(() => {
        if (!ler) setLer(flow?.ler);
        if (!manager) setManager(flow?.manager);
        if (isMounted) props.onFlowChange?.(flow);
    }, [flow?.id]);

    useEffect(() => {
        if (isMounted) props.onCarrierChange?.(carrier);
    }, [carrier?.id]);

    return (
        <div className="WasteFlowCarrierSelector" data-testid="WasteFlowCarrierSelector">
            <div className="WasteFlowCarrierSelector__fields">
                <LerSelector
                    value={ler}
                    onChange={(ler) => {
                        setLer(ler);
                        setManager(undefined);
                        setCarrier(undefined);
                        setFlow(undefined);
                    }}
                    filters={{ promotionId }}
                />

                <WasteManagerSelector
                    value={manager}
                    isClearable={!!ler}
                    onChange={(manager) => {
                        setManager(manager);
                        setFlow(undefined);
                    }}
                    filters={{ promotionId, ler }}
                    disabled={!ler}
                />

                <CarrierSelector
                    value={carrier}
                    isClearable={!!ler}
                    onChange={(carrier) => setCarrier(carrier)}
                    filters={{ promotionId, ler }}
                    disabled={!ler}
                />
                <div className="WasteFlowCarrierSelector__info">
                    <WasteFlowInfo flow={flow} />
                    {!!ler && !!manager && !flow && (
                        <div
                            className={`WasteFlowCarrierSelector__spinner ${loading === 'pending' ? '--animate' : ''}`}
                        >
                            <IconInnerShadowTopRight />
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default WasteFlowCarrierSelector;
