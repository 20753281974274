import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconBan, IconCheckbox, IconExternalLink, IconRotateClockwise2 } from '@tabler/icons';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import { useAutomaticReadingNotifications } from 'features/automatic-reading';
import TraceabilityFieldset from 'features/traceabilities/components/LegacyTraceabilityFieldset';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useDiExtraction } from 'modules/di-extraction';
import { IApproveDiExtractionData } from 'modules/di-extraction/domain/IApproveDiExtractionData';
import { IDiExtraction } from 'modules/di-extraction/domain/IDiExtraction';
import { usePromotion } from 'modules/promotion';
import { DomainException } from 'modules/shared';
import { TraceabilitySaver } from 'modules/traceabilities';
import './DiExtraction.scss';
import { useDiExtractionWarnings } from './DiExtractionWarnings/useDiExtractionWarnings';

interface DiExtractionProps {
    diExtraction: IDiExtraction;
}

const DiExtraction = ({ diExtraction }: DiExtractionProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    const [, analytics] = useAnalyticsStore();
    const notifications = useAutomaticReadingNotifications();
    const [direction, setDirection] = useState<'H' | 'V'>('H');
    const [traceability, setTraceability] = useState<TraceabilitySaver>({
        ...diExtraction.result,
        id: undefined,
        incomplete: false,
        observations: '',
        carrier: null
    });

    const [{ loading, invalid, updating: removing }, { approveDiExtraction, rejectManyDiExtractions }] =
        useDiExtraction();

    const { getWarnings } = useDiExtractionWarnings({ diExtraction });

    const error = invalid[diExtraction.id];
    const isRemoving = removing.includes(diExtraction.id);

    const approveExtraction = async () => {
        const approveDiExtractionData: IApproveDiExtractionData = {
            id: diExtraction.id,
            ppgclId: traceability.ppgcl?.id || null,
            date: traceability.date!,
            numDi: traceability.numDi,
            weight: traceability.weight!,
            cost: traceability.cost,
            volume: traceability.volume,
            vehiclePlate: traceability.vehiclePlate,
            incomplete: traceability.incomplete,
            observations: traceability.observations
        };

        const createdExtraction = await approveDiExtraction(approveDiExtractionData);

        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_APPROVED);

        if (createdExtraction?.traceabilityId) notifications.created(createdExtraction.traceabilityId);
    };

    const rejectExtraction = async () => {
        await rejectManyDiExtractions({ ids: [diExtraction.id], promotionId: promotion?.id });
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_DETAIL_REJECTED);
    };

    const toggleDirection = () => {
        if (direction === 'H') setDirection('V');
        else setDirection('H');
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_PREVIEW_ROTATED);
    };

    const hasPreview = ['pdf', 'jpg', 'png'].includes(diExtraction.mimeType);
    const isImagePreview = ['jpg', 'png'].includes(diExtraction.mimeType);
    const isPdfPreview = diExtraction.mimeType === 'pdf';

    const modifiers = {
        hasPreview: hasPreview ? 'diExtraction--has-preview' : '',
        isRemoving: removing.includes(diExtraction.id) ? 'diExtraction--removing' : '',
        isPending: loading === 'pending' ? 'diExtraction--pending' : '',
        displayDirection:
            (direction === 'V' && 'diExtraction--vertical') || (direction === 'H' && 'diExtraction--horizontal')
    };

    return (
        <div className={`diExtraction ${Object.values(modifiers).join(' ')}`} data-testid="DiExtractionDetail">
            <div className="diExtraction__preview">
                {isPdfPreview && <iframe src={diExtraction.fileUrl} />}
                {isImagePreview && <img src={diExtraction.fileUrl} />}
                {!hasPreview && <p className="diExtraction__previewExtension">{diExtraction.mimeType}</p>}

                <div className="diExtraction__iframeButtons">
                    <SmallButton
                        type="button"
                        titulo={t('rotate', { ns: 'diExtraction' })}
                        icon={<IconRotateClockwise2 />}
                        iconPosition="left"
                        action={() => toggleDirection()}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    {diExtraction.fileUrl && (
                        <SmallButton
                            type="external-link"
                            titulo={t('show.show')}
                            icon={<IconExternalLink />}
                            iconPosition="left"
                            to={diExtraction.fileUrl}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    )}
                </div>
            </div>
            <form
                className="diExtraction__form"
                onSubmit={(e) => {
                    e.preventDefault();
                    approveExtraction();
                }}
            >
                <TraceabilityFieldset
                    initialTraceabilityData={traceability}
                    getInputExtraElement={getWarnings}
                    onChange={(form) => setTraceability({ ...traceability, ...form })}
                ></TraceabilityFieldset>

                {error && (
                    <ErrorCard error={error}>
                        {error instanceof DomainException && (
                            <ul>
                                {error?.cause?.content?.errors?.map((e) => (
                                    <li key={e.field}>{e.error}</li>
                                ))}
                            </ul>
                        )}
                    </ErrorCard>
                )}

                <div className="diExtraction__buttons">
                    <BasicButton
                        type="button"
                        color="red"
                        text={t('reject', { ns: 'diExtraction' })}
                        icon={<IconBan stroke={2} size={20} color="white" />}
                        extraClasses="button__small"
                        isLoading={isRemoving}
                        action={() => rejectExtraction()}
                    />
                    <SaveButton
                        type="submit"
                        color="green"
                        text={t('approve', { ns: 'diExtraction' })}
                        icon={<IconCheckbox stroke={2} size={20} color="white" />}
                        extraClasses="button__small"
                        isLoading={isRemoving}
                    />
                </div>
            </form>
        </div>
    );
};

export default DiExtraction;
