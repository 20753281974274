import { LegacyWasteFlow } from 'modules/waste-flow';

export interface IDiExtraction {
    id: string;
    mimeType: string;
    fileUrl: string;
    fileName: string;
    userMail: string;
    origin: keyof typeof DiExtrationOrigin;
    requestDate: Date;
    traceabilityId: number | null; // useful when status is already processed (link to existing traceability)
    state: keyof typeof DiExtrationStatus;
    result: {
        ppgcl: LegacyWasteFlow | null;
        date: string | null;
        weight: number | null;
        cost: number | null;
        volume: number | null;
        numDi: string | null;
        vehiclePlate: string | null;
    };
    warnings: DiExtractionWarning[];
}

export type DiExtractionWarningFields = 'weight' | 'numDi' | 'date' | 'ppgcl';

export interface DiExtractionWarning {
    code: string;
    severityLevel: 'LOW' | 'HIGH';
    message: string;
    fields: Array<DiExtractionWarningFields>;
}

export type Counters = {
    [status in keyof typeof DiExtrationStatus]: number | null;
} & { totalApplicable: number | null };

export enum DiExtrationStatus {
    PENDING = 'PENDING',
    ALREADY_PROCESSED = 'ALREADY_PROCESSED',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    NOT_VALID = 'NOT_VALID',
    PENDING_REVIEW = 'PENDING_REVIEW',
    DOCUMENT_UPLOAD_ERROR = 'DOCUMENT_UPLOAD_ERROR'
}

export enum DiExtrationOrigin {
    'EMAIL' = 'EMAIL',
    'WEB' = 'WEB'
}
