import { RESPONSABILITY_FIELD_COCIRCULAR_ID, RESPONSABILITY_MANAGER_COCIRCULAR_ID } from '../constants/user.constants';

export const checkIfCoCircularResponsability = (roleTypeId) => {
    if (roleTypeId === RESPONSABILITY_FIELD_COCIRCULAR_ID || roleTypeId === RESPONSABILITY_MANAGER_COCIRCULAR_ID) {
        return false;
    }
    return true;
};

export const isCocircularManager = (user) => {
    return user.roleType?.id === RESPONSABILITY_MANAGER_COCIRCULAR_ID;
};
