import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import { LerProvider } from 'modules/ler';
import { httpLerApi } from 'modules/ler/infrastructure/api/httpLerApi';
import { usePromotion } from 'modules/promotion';
import { ReuseSaver } from 'modules/traceabilities/domain/Reuse/ReuseSaver';
import ReuseLerSelector from './ReuseLerSelector/ReuseLerSelector';
import './ReuseForm.scss';

interface ReuseFormProps {
    initialReuseData: ReuseSaver;
    onChange?: (reuseData: Partial<ReuseSaver>) => void;
    error?: Error | null;
}

const ReuseForm = (props: ReuseFormProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [reuse, setReuse] = useState(props.initialReuseData);

    const updateReuse = (newData: ReuseSaver) => {
        setReuse(newData);
        props.onChange?.(newData);
    };

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        updateReuse({ ...reuse, [name]: value });
    };

    useEffect(() => {
        if (!props.initialReuseData) return;
        setReuse(props.initialReuseData);
    }, [JSON.stringify(props.initialReuseData || {})]);

    if (!promotion) return null;

    return (
        <div className="ReuseForm">
            <LerProvider api={httpLerApi}>
                <ReuseLerSelector
                    value={reuse.ler}
                    disabled={props.initialReuseData.id !== undefined}
                    onChange={(ler) => updateReuse({ ...reuse, ler })}
                />
            </LerProvider>

            <div className="ReuseForm__break" />

            <InputFecha
                inputContainer="ReuseForm__input-date"
                className="ReuseForm__input"
                label={t('date.date', { ns: 'common' })}
                name="date"
                value={reuse.date}
                values={reuse}
                setValues={updateReuse}
                onChange={handleInputChange}
                validacion={!!props.error}
                maxDays={0}
                required
                {...({} as any)}
            />

            <InputNumber
                className="ReuseForm__input ReuseForm__input-small"
                label={t('weight.upperCase', { ns: 'common' }) + ' (T)'}
                name="weight"
                id="weight"
                value={reuse.weight || ''}
                showThousandSeparator={false}
                onChange={handleInputChange}
                validacion={!!props.error}
                required
                maximumFractionDigits={3}
                min="1"
                extraElement={null}
                {...({} as any)}
            />

            <InputNumber
                className={`ReuseForm__input ReuseForm__input-small`}
                label={t('volume.upperCase', { ns: 'traceability' }) + ' (m³)'}
                name="volume"
                value={reuse.volume || ''}
                onChange={handleInputChange}
                validacion={!!props.error}
                required
                {...({} as any)}
            />

            <div className="ReuseForm__break ReuseForm__break--always" />

            <TextArea
                label={t('detail.observations', { ns: 'traceabilities' })}
                name="observations"
                value={reuse.observations}
                onChange={handleInputChange}
                className="ReuseForm__input ReuseForm__observations"
                inputContainer="ReuseForm__input-fullWidth"
                {...({} as any)}
            />
        </div>
    );
};

export default ReuseForm;
