import { ReuseSaver } from 'modules/traceabilities/domain/Reuse/ReuseSaver';
import { DocumentApiData, TraceabilityApiData } from './traceabilityAdapter';

type Sources = {
    data: TraceabilityApiData;
    reuseDoc?: DocumentApiData;
};

export const traceabilityToReuseAdapter = ({ data, reuseDoc }: Sources): ReuseSaver => {
    const reuse: ReuseSaver = {
        id: data.id.toString(),
        ler: {
            id: parseInt(data.lerId),
            code: data.lerCode,
            type: data.lerTypeName
        },
        promotionId: data.project.id,
        date: data.movementDate,
        observations: data.observations,
        reuseDoc:
            reuseDoc?.id !== undefined
                ? {
                      id: reuseDoc.id,
                      name: reuseDoc.name,
                      url: reuseDoc.url
                  }
                : null,

        volume: data.volume,
        weight: data.weight
    };

    return reuse;
};
