import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { Ler } from 'modules/ler';
import { useCarrierStore, LegacyCarrier } from 'modules/carrier';
import './CarrierSelector.scss';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { WasteManager } from 'modules/waste-manager';

type CarrierSelectorProps = {
    onChange?: (carrier: LegacyCarrier) => void;
    value?: LegacyCarrier | null;
    disabled?: boolean;
    isClearable?: boolean;
    filters?: {
        promotionId?: number;
        manager?: WasteManager;
        ler?: Ler;
    };
};

const carrierOptionAdapter = (carrier): SelectOption => ({
    value: carrier.name,
    label: carrier.name,
    carrier
});

const CarrierSelector = (props: CarrierSelectorProps) => {
    const { filters } = props;
    const [t] = useTranslation();
    const [, , { api }] = useCarrierStore();

    const [loadCarrierOptions] = useAsyncOptions({
        removePagination: true,
        adapter: carrierOptionAdapter,
        request: ({ search }) =>
            api.legacyFindCarrierByCriteria({
                promotionId: filters?.promotionId,
                search,
                size: 999, // Because we need to group by manager in the frontend
                ler: { code: filters?.ler?.code },
                manager: { name: filters?.manager?.name }
            })
    });

    return (
        <SelectAutocomplete
            // Forces re-render when the filters change to reload the options with the new filters
            key={JSON.stringify(filters)}
            dataTestId="carrier-selector"
            name="carrier"
            label={t('transporter')}
            loadOptions={loadCarrierOptions}
            defaultOptions={false}
            disabled={props.disabled}
            isClearable={props.isClearable}
            value={props.value ? carrierOptionAdapter(props.value) : undefined}
            onChange={(option) => {
                if (Array.isArray(option)) return;
                props.onChange?.(option?.carrier);
            }}
        />
    );
};

export default CarrierSelector;
