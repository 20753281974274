const CHART_COLORS = {
    real: '#7F42F5',
    potential: '#D1F0DA',
    standar: '#B3B3FF'
};

export const labelOptions = {
    real: { label: 'Real', color: '#7F42F5', id: 'real' },
    potential: { label: 'Potencial', color: '#D1F0DA', id: 'potential' },
    standard: { label: 'Media en España', color: '#B3B3FF', id: 'standard' },
    mix: { label: 'Potencial mezclas', color: '#70987d', id: 'mix' }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // Don't change the color order. Depend of Graph type,
    //it will be rendered in the order of the colors or inverse.
    colorsLineChart: [CHART_COLORS.real, CHART_COLORS.potential, CHART_COLORS.standar],
    colorsBarChart: [CHART_COLORS.real, CHART_COLORS.potential, CHART_COLORS.standar],
    margin: { top: 10, right: 50, bottom: 75, left: 50 },
    theme: {
        axis: {
            tickColor: 'white',
            ticks: {
                line: {
                    stroke: 'white'
                },
                text: {
                    fill: '#273749',
                    fontSize: 9,
                    fontFamily: 'Be Vietnam Pro'
                }
            }
        }
    },

    axisBottom: {
        tickSize: 0,
        tickPadding: 25,
        tickRotation: 0,
        renderTick: ({ opacity, textAnchor, value, x, y }) => {
            return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                    <text transform="rotate(-50) translate(-28,15)" textAnchor={textAnchor} fontSize="10px">
                        {value}
                    </text>
                </g>
            );
        }
    }
};
