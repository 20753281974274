import { logger } from 'logger';
import { httpTraceabilitiesApi, TraceabilitiesProvider } from 'modules/traceabilities';
import NewReuse from '../components/NewReuse';

const NewReusePage = () => {
    return (
        <TraceabilitiesProvider api={httpTraceabilitiesApi} logger={logger}>
            <NewReuse />
        </TraceabilitiesProvider>
    );
};

export default NewReusePage;
