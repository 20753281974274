import { IconFileAnalytics, IconInfoCircle } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import promotionServices from 'api/promotion/promotion.services';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import Card from 'components/BasicComponents/Cards/SimpleCard';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import EnterpiseAutocomplete from 'components/BasicComponents/Select-Autocomplete/EnterpiseAutocomplete';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import { withAuth } from 'context/AuthProvider';
import { useQuery } from 'hooks/useQuery';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import Layout from 'pages/Layout/Layout';

import { getGraphicLink, getPromoIdArrayFormatted } from '../helpers/biuwer.helpers';
import './DashboardPage.scss';
import PromotionMultiSelect from './PromotionMultiSelect';

const Dashboard = ({ isCoCircular, user }) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const { params } = useQuery();

    const [values, setValues] = useState({ enterprise: '', promotions: [], dateTo: params.dateTo });
    const history = useHistory();
    const isPromotionDashboard = history.location.pathname.includes('promotion');
    const [selectedPageId, setSelectedPageId] = useState(null);
    const [currentGraphicLink, setCurrentGraphicLink] = useState('');

    const GRAPHIC_PAGES = [
        {
            id: 0,
            title: t('tabs.results', { ns: 'dashboard' }),
            graphicResourceToken: 'c3b22640-b8e4-11ed-af2f-e73b05453057',
            showTab: !isPromotionDashboard
        },
        {
            id: 1,
            title: t('tabs.results', { ns: 'dashboard' }),
            graphicResourceToken: '8958dcf0-b8e4-11ed-af2f-e73b05453057',
            showTab: isPromotionDashboard
        },
        {
            id: 2,
            title: t('tabs.treatment', { ns: 'dashboard' }),
            graphicResourceToken: 'c96cabf0-b8e4-11ed-af2f-e73b05453057',
            showTab: true
        },
        {
            id: 3,
            title: t('tabs.greenConsulting', { ns: 'dashboard' }),
            graphicResourceToken: '0a6aac20-ee54-11ed-8d1a-9d22cd0adc95',
            showTab: !isPromotionDashboard
        }
    ];

    useEffect(() => {
        if (isPromotionDashboard) {
            setSelectedPageId(1); // results by project
        } else {
            setSelectedPageId(0); // results by multiproject
        }
    }, []);

    useEffect(() => {
        if (promotion?.id && isPromotionDashboard) {
            setValues({ ...values, promotions: [promotion] });
        } else if (!isCoCircular && !isPromotionDashboard) {
            searchUserPromotions(user.promotionIds);
        }
    }, [promotion]);

    useEffect(() => {
        // reset selected promotions when cocircular changes enterprise
        if (isCoCircular && !isPromotionDashboard) {
            setValues({ ...values, promotions: [] });
        }
    }, [values.enterprise]);

    useEffect(() => {
        if (values?.promotions?.length > 0 && selectedPageId !== null) {
            changeGraphicLink();
        }
    }, [selectedPageId, promotion, values?.promotions, values?.dateTo]);

    const searchUserPromotions = async (promotionIds) => {
        const promotions = await promotionServices.filterPromotion(`?id.in=${promotionIds}&size=999`);
        setValues({ ...values, promotions });
    };

    const changeSelectedPage = (pageId) => {
        setSelectedPageId(pageId);
    };

    const changeGraphicLink = () => {
        const selectedPage = GRAPHIC_PAGES?.find((page) => page.id === selectedPageId);
        const promoIds = getPromoIdArrayFormatted(values.promotions);

        const dateToFilter = values?.dateTo ? `&fechaHasta=${values.dateTo}` : '';
        const link = getGraphicLink(selectedPage?.graphicResourceToken, promoIds, dateToFilter);

        setCurrentGraphicLink(link);
    };

    const handleInputChange = ({ target }) => {
        setValues({ ...values, [target.name]: target.value });
    };

    return (
        <Layout>
            <TituloH1 titulo={t(isPromotionDashboard ? 'dashboard' : 'globalDashboard', { ns: 'dashboard' })} />

            <Card className="dashboard">
                {isPromotionDashboard && (
                    <header className="dashboard__header">
                        <SmallButton
                            titulo={t('showReport', { ns: 'dashboard' })}
                            icon={<IconFileAnalytics />}
                            action={() => history.push(`/promotion/${promotion.id}/reports`)}
                            type="button"
                            color="blue"
                        />
                    </header>
                )}
                {!isPromotionDashboard && (
                    <div className="dashboard__filterContainer">
                        {isCoCircular && (
                            <EnterpiseAutocomplete
                                {...{ values, setValues }}
                                isClearable={true}
                                propName="enterprise"
                                promotion={values.promotions}
                            />
                        )}
                        <PromotionMultiSelect
                            values={values}
                            setValues={setValues}
                            isClearable={true}
                            propName="promotions"
                            enterprise={values.enterprise}
                            className="dashboard__select"
                        />
                    </div>
                )}
                {isPromotionDashboard && values.dateTo !== undefined && (
                    <div className="dashboard__filterContainer">
                        <InputFecha
                            label={t('date.dateTo', { ns: 'common' })}
                            name="dateTo"
                            value={values?.dateTo}
                            values={values}
                            setValues={setValues}
                            onChange={handleInputChange}
                            notLimit={true}
                        />
                    </div>
                )}

                <div className="dashboard__alert">
                    <AlertCard icon={<IconInfoCircle size={24} />}>
                        {t('biuwerExportTip', { ns: 'dashboard' })}
                    </AlertCard>
                </div>

                <div className="dashboard__buttonContainer">
                    {GRAPHIC_PAGES?.map((page) => {
                        return (
                            page?.showTab && (
                                <button
                                    key={page?.id}
                                    className={
                                        selectedPageId === page?.id
                                            ? `dashboard__btn dashboard__btn-active`
                                            : `dashboard__btn`
                                    }
                                    onClick={() => changeSelectedPage(page?.id)}
                                >
                                    {page?.title}
                                </button>
                            )
                        );
                    })}
                </div>
                <div className="dashboard__content">
                    <iframe width="100%" height="1800" src={currentGraphicLink}></iframe>
                </div>
            </Card>
        </Layout>
    );
};
export default withAuth(Dashboard);
