import fetcher from 'lib/fetcher';

import { WasteManager } from '../../domain/WasteManager';
import { WasteManagerApi } from '../../domain/WasteManagerApi';

type ApiWasteFlow = {
    manager: {
        id: number;
        name: string;
    };
};

export const httpWasteManagerApi: WasteManagerApi = {
    async findWasteManagerByCriteria(criteria) {
        const { promotionId } = criteria || {};

        /** From PPGCL */
        if (promotionId !== undefined) {
            const params = {
                'promocionId.equals': promotionId,
                'codLer.contains': criteria?.ler?.code,
                size: criteria?.size || 999,
                page: criteria?.page || 0,
                ...(criteria?.search !== '' && { 'search.contains': criteria?.search })
            };

            const { data } = await fetcher.get('/api/project-waste-flow', {
                params
            });

            const wasteFlows = (data?.content || []).filter((wf: ApiWasteFlow) => !!wf.manager);
            const groupedWasteFlowsByWasteManager = Object.groupBy(wasteFlows, (wf: ApiWasteFlow) => wf.manager.name);

            const wasteManagers = Object.values(groupedWasteFlowsByWasteManager)
                .filter((group) => group && group.length > 0)
                .map((group) => {
                    const g = group as ApiWasteFlow[];
                    const source = g[0].manager;

                    const wasteManager: WasteManager = {
                        /** @provisional replace with id */
                        id: source.id,
                        name: source.name
                    };

                    return wasteManager;
                });

            return wasteManagers;
        }

        return [];
    }
};
