import userServices from '../../api/user/user.services';

export const crearMaxDate = (numberOfDaysToAdd = 15) => {
    let today = new Date();
    today.setDate(today.getDate() + numberOfDaysToAdd);
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    today = yyyy + '-' + mm + '-' + dd;
    return today;
};

export const cambiarOrdenFecha = (fecha) => {
    if (fecha === undefined || fecha === null) {
        return '';
    }

    let today = new Date(fecha);
    let date =
        ('0' + today.getDate()).slice(-2) + '/' + ('0' + (today.getMonth() + 1)).slice(-2) + '/' + today.getFullYear();

    return date;
};

export const getDiffDays = (init, end) => {
    let initDate = new Date(init);
    let endDate = new Date(end);

    let initEndDiff = endDate.getTime() - initDate.getTime();
    let countDays = Math.round(initEndDiff / (1000 * 60 * 60 * 24));

    return countDays;
};

export const getYearMonthDay = () => {
    let today = new Date();
    let date =
        today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
    return date;
};

export const convertToYYYYMMDDFormat = (date) => {
    let convertedDate = getYearMonthDay(date);
    convertedDate = convertedDate.replaceAll('-', '');

    return convertedDate;
};

export const roundNumber = (number, decimals = 2) => {
    // https://stackoverflow.com/a/12830454
    return +(number || 0).toFixed(decimals);
};

export const capitalize = (str) => {
    if (str === undefined || str === null) {
        return '';
    }
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
};

export function currencyFormatDE(num, maximumFractionDigits = 2) {
    if (num === undefined) {
        return '';
    }

    const newNumber = Intl.NumberFormat('de-DE', {
        maximumFractionDigits
    }).format(num);
    return newNumber;
}

//devuelve true si hay algun valor del obj que está lleno, si está todo vacío devuelve false
export const keyValueObjNotEmpty = (fields) => {
    for (let i in fields) {
        if (fields[i] !== '' && fields[i] !== undefined && fields[i] !== null && fields[i].length !== 0) {
            return true;
        }
    }
    return false;
};
//comprueba que no haya campos vacios, si hay alguno devuelve false
export const allFieldsComplete = (fields) => {
    for (let i in fields) {
        if (fields[i] === '' || fields[i] === null || fields[i] === undefined) {
            return false;
        }
    }
    return true;
};

//crea el filtro a partir de los values y el objFilter que le pases
export const createFilterWithObject = (values, objFilter) => {
    let createFilter = '';
    for (let i in values) {
        if (values[i] && values[i].length !== 0) {
            if (objFilter[i] !== undefined) {
                createFilter += objFilter[i];
            }
        }
    }
    return createFilter;
};

export const removeDuplicates = (originalArray, prop = 'id') => {
    const arrayWithoutDuplicates = [...new Map(originalArray.map((item) => [item[prop], item])).values()];
    return arrayWithoutDuplicates;
};

/* Returns elements that exists in the first array (main array) but don't exist in the second one */
export const differenceArrays = (array1, array2, prop = 'id') => {
    return array1.filter((obj1) => !array2.some((obj2) => obj1[prop] === obj2[prop]));
};

export const searchUserClient = async (id) => {
    const clienteUser = await userServices.getUserById(id);
    return clienteUser;
};

export const removeAccentedCharacters = (string) => {
    let newStr = string.toLowerCase();
    newStr = newStr.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    newStr = newStr.replace(new RegExp('ç', 'g'), 'c');
    newStr = newStr.replace(new RegExp('[èéêë]', 'g'), 'e');
    newStr = newStr.replace(new RegExp('[ìíîï]', 'g'), 'i');
    newStr = newStr.replace(new RegExp('ñ', 'g'), 'n');
    newStr = newStr.replace(new RegExp('[òóôõö]', 'g'), 'o');
    newStr = newStr.replace(new RegExp('[ùúûü]', 'g'), 'u');
    newStr = newStr.replace(new RegExp('[ýÿ]', 'g'), 'y');
    return newStr;
};
