// eslint-disable-next-line import/named
import { IconConfetti, IconForbid, IconSearch, IconTiltShift, TablerIcon } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import './EmptyState.scss';

interface EmptyStateProps {
    mode?: 'notFound' | 'emptyResults' | 'happyEmptyState' | 'forbidden';
    className?: string;
    subject?: string;
    icon?: TablerIcon;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    testid?: string;
}

const iconSize = 56;

const EmptyState = (props: EmptyStateProps) => {
    const [t] = useTranslation();

    const subject = props.subject || t('emptyResults.genericSubject');

    return (
        <div className={`EmptyState ${props.className || ''}`} data-testid={props.testid}>
            {props.mode === 'emptyResults' && <IconTiltShift size={iconSize} />}
            {props.mode === 'notFound' && <IconSearch size={iconSize} />}
            {props.mode === 'happyEmptyState' && <IconConfetti size={iconSize} />}
            {props.mode === 'forbidden' && <IconForbid size={iconSize} />}
            {props.icon && <props.icon size={iconSize} />}

            <h2>{props.title || t(`${props.mode}.title`, { defaultValue: null, subject })}</h2>
            <p>{props.subtitle || t(`${props.mode}.tip`, { defaultValue: null, subject })}</p>
        </div>
    );
};

export default EmptyState;
