import fetcher from '../../../lib/fetcher';

export const getCarriers = async (filter = '') => {
    const URL = `/api/waste-manager?hasPgcl.equals=true&sort=name,asc&valuationCode.equals=T01&estadoId.notEquals=5604${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
