import { useEffect } from 'react';
import { Redirect, Switch, useParams } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import { withAuth } from 'context/AuthProvider';
import { httpPromotionApi } from 'modules/promotion/infrastructure/api/httpPromotionApi';
import { PromotionProvider, usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { INTERNAL_PROMOTION_ROUTES, promotionRouteRoot } from '../../routes/PromotionRouter';
import './Promotion.scss';

interface PromotionProps {
    changePromotion: (promocion: any) => void;
}

const Promotion = (props: PromotionProps) => {
    const { promotionId } = useParams<{ promotionId: string }>();
    const [{ promotion }, { loadPromotion }] = usePromotion();

    useEffect(() => {
        const id = parseInt(promotionId);
        if (isNaN(id)) return;

        loadPromotion(id);
    }, [promotionId]);

    // For old implementation, setting inside SessionStorage
    // Delete when the promotion goes out of AuthProvider
    useEffect(() => {
        props.changePromotion(promotion);
    }, [promotion]);

    if (!promotion) return null;

    return (
        <>
            <Switch>
                {INTERNAL_PROMOTION_ROUTES.map((route: any) => (
                    <PrivateRoute {...route} key={route.path} />
                ))}
                <Redirect to={promotionRouteRoot} />
            </Switch>
        </>
    );
};

export default withAuth((props) => {
    return (
        <PromotionProvider api={httpPromotionApi}>
            <Promotion {...props} />
        </PromotionProvider>
    );
});
