export const BIM_MODEL_ID = 490351;
const INVOICE_TYPE_ID = 86153;
const CERTIFICATE_TYPE_ID = 87301;
const REUSE_CERTIFICATE_TYPE_ID = 640151;
export const ASSOCIABLE_TYPES_IDS = [INVOICE_TYPE_ID, CERTIFICATE_TYPE_ID, REUSE_CERTIFICATE_TYPE_ID];

// DI, certificado mensual planta, contrato de tratamiento peligr., contrato de tratamiento NP, certificado aprovechamiento % planta
export const VALID_DOWNLOAD_IDS = [86151, 87301, 412001, 675001, 425401];

export const SORT = [
    {
        label: 'DESC_DATE'
    },
    {
        label: 'ASC_DATE'
    },
    {
        label: 'ASC_FILENAME'
    },
    {
        label: 'DESC_FILENAME'
    }
];
