import WallePanel from './components/WallePanel';
import WalleList from './components/WalleList';

import './Walle.scss';

interface WalleProps {}

const Walle = (props: WalleProps) => {
    return (
        <div className="Walle">
            <div className="Walle__list">
                <WalleList />
            </div>
            <div className="Walle__preview">
                <WallePanel />
            </div>
        </div>
    );
};

export default Walle;
