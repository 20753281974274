import { IconCheck, IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { useKey } from 'hooks/useKey';
import { NotificationSetting } from 'modules/notification-settings/domain/NotificationSetting';
import { useNotificationSettings } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { usePromotion } from 'modules/promotion';
import { Loading } from 'modules/shared/domain/loading';
import { useRef, useState } from 'react';
import uuid from 'react-uuid';
import { debounce } from 'utils/helpers/async.helpers';
import { useLoadEmails } from './hooks/useLoadEmails';
import { useLoadStakeholders } from './hooks/useLoadStakeholders';
import './StakeholderEmailRow.scss';

interface StakeholderEmailRowProps {
    notificationSetting?: NotificationSetting;
    className?: string;
    onSave?: (result: Loading) => void;
    onRemove?: (result: Loading) => void;
    stakeholderType: 'waste-manager' | 'carrier';
}

const StakeholderEmailRow = (props: StakeholderEmailRowProps) => {
    const [t] = useTranslation('NotificationSettings');
    const selectRef = useRef<any>(null);
    const [{ promotion }] = usePromotion();
    const [{ loading, updatingId }, { saveNotificationSetting, deleteNotificationSetting }] = useNotificationSettings();

    const mode: 'edit' | 'create' = !!props.notificationSetting ? 'edit' : 'create';
    const initialNotification = props.notificationSetting || createEmptyNotificationSetting(props.stakeholderType);
    const [notificationSetting, setNotificationSetting] = useState(initialNotification);
    const [writtenEmail, setWrittenEmail] = useState('');

    const { loadStakeholders } = useLoadStakeholders(props.stakeholderType);
    const { loadEmails } = useLoadEmails(notificationSetting.data.stakeholderId);

    //

    const updateState = (data: Partial<NotificationSetting>) => {
        const updatedNotificationSetting = { ...notificationSetting, ...data } as NotificationSetting;
        setNotificationSetting(updatedNotificationSetting);

        // Deferred save when notification setting updates in edit mode
        const hasChanges = JSON.stringify(notificationSetting) !== JSON.stringify(updatedNotificationSetting);
        if (mode === 'edit' && hasChanges) debounce(save)(updatedNotificationSetting);
    };

    const save = async (notificationSettingToSave: NotificationSetting) => {
        await saveNotificationSetting(notificationSettingToSave, promotion?.id || NaN);

        // const { loading } = await mutate({ goal, kpi: props.kpi, promotionId: promotion?.id || NaN });
        // if (loading === 'succeeded' && mode === 'create') setGoal(createEmptyGoal());

        if (mode === 'create') setNotificationSetting(createEmptyNotificationSetting(props.stakeholderType));
        props.onSave?.(loading);
    };

    const modifiers = [props.className || ''];
    // , saving === 'failed' ? 'WMPlannerItem--mutation-error' : ''];

    useKey('Escape', () => setWrittenEmail(''), [], notificationSetting.id);
    useKey(
        'Enter',
        () => {
            setWrittenEmail('');
            updateState({ emails: [...notificationSetting.emails, writtenEmail] });
            selectRef.current?.blur();
        },
        [],
        notificationSetting.id
    );

    return (
        <tr
            className={`StakeholderEmailRow ${modifiers.join(' ')}`}
            // data-testid={`StakeholderEmailRow__${props.goal ? goal.uuid : 'empty'}`}
        >
            <td>
                <SelectAutocomplete
                    name="stakeholder"
                    className="StakeholderEmailRow__selector"
                    menuPosition="fixed"
                    value={
                        isNaN(notificationSetting.data.stakeholderId)
                            ? null
                            : {
                                  value: notificationSetting.data.stakeholderId,
                                  label: notificationSetting.data.stakeholderName
                              }
                    }
                    onChange={(option) =>
                        updateState({
                            data: {
                                stakeholderId: (option as SelectOption).value,
                                stakeholderName: (option as SelectOption).label as string,
                                stakeholderType: props.stakeholderType
                            }
                        })
                    }
                    loadOptions={loadStakeholders}
                    placeholder={t('stakeholderInput.placeholder')}
                    isClearable={false}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td className="StakeholderEmailRow__emailColumn">
                <SelectAutocomplete
                    id={notificationSetting.id}
                    key={notificationSetting.data.stakeholderId}
                    ref={selectRef}
                    value={notificationSetting.emails.map((email) => ({ value: email, label: email }))}
                    loadOptions={
                        // if there is no selected stakeholder yet or if user is writting an email, don't load emails
                        isNaN(notificationSetting.data.stakeholderId) || writtenEmail !== ''
                            ? async () => ({ options: [] as any, hasMore: false, additional: { page: 0 } })
                            : loadEmails
                    }
                    onInputChange={(value) => setWrittenEmail(value)}
                    onChange={(options) => {
                        // options == all emails on the input
                        updateState({ emails: options?.map((option) => option.value) });
                    }}
                    placeholder={t('emailInput.placeholder')}
                    noOptionsMessage={t('emailInput.write')}
                    isClearable={false}
                />
            </td>

            <td>
                {mode === 'edit' && (
                    <ActionConfirm
                        onConfirm={async () => {
                            await deleteNotificationSetting(notificationSetting.id);
                            props.onRemove?.(loading);
                        }}
                    >
                        <IconButton
                            ariaLabel={t('delete')}
                            tabIndex={-1}
                            titulo="delete"
                            icon={<IconX size={16} />}
                            isLoading={loading === 'updating' && updatingId === notificationSetting.id}
                            disabled={loading === 'updating' && updatingId === notificationSetting.id}
                        />
                    </ActionConfirm>
                )}
                {mode === 'create' && (
                    <IconButton
                        ariaLabel={t('add')}
                        titulo={t('add')}
                        action={() => save(notificationSetting)}
                        icon={<IconCheck size={16} />}
                        isLoading={loading === 'updating' && updatingId === notificationSetting.id}
                        disabled={loading === 'updating' && updatingId === notificationSetting.id}
                    />
                )}
            </td>
        </tr>
    );
};

function createEmptyNotificationSetting(stakeholderType) {
    const notificationSetting: NotificationSetting = {
        id: uuid(),
        emails: [],
        type: 'waste-register',
        data: {
            stakeholderId: NaN,
            stakeholderName: '',
            stakeholderType: stakeholderType
        }
    };
    return notificationSetting;
}

export default StakeholderEmailRow;
