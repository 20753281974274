import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePromotion } from 'modules/promotion';
import { useGetReuseDetail, useSaveReuse } from 'modules/traceabilities';
import { ReuseSaver } from 'modules/traceabilities/domain/Reuse/ReuseSaver';
import { getEmptyReuseSaver } from 'modules/traceabilities/domain/utils/reuse.helpers';
import { notify } from 'utils/helpers/notifications';

interface UseReuseFormProps {
    reuseId?: string;
}

export function useReuseForm(props: UseReuseFormProps) {
    const [t] = useTranslation('reuse');
    const [{ promotion }] = usePromotion();

    const { data, loading, error, refetch } = useGetReuseDetail({
        promotionId: promotion?.id,
        id: props.reuseId
    });

    const [reuse, setReuse] = useState<ReuseSaver>(getEmptyReuseSaver());

    const saveReuse = useSaveReuse();

    const save = async () => {
        const payload: ReuseSaver = { ...reuse, promotionId: promotion?.id || NaN };

        const result = await saveReuse.mutate(payload);
        return result;
    };

    const reset = () => {
        setReuse(getEmptyReuseSaver());
    };

    useEffect(() => {
        if (data?.id !== undefined) setReuse(data);
    }, [data?.id]);

    useEffect(() => {
        if (saveReuse.loading === 'succeeded') {
            const nextStep = reuse.id === undefined ? reset : refetch;
            nextStep();
        }
        const msg = t(`form.save.${saveReuse.loading}`, { defaultValue: null });
        if (!msg) return;

        notify({ msg, isSuccess: saveReuse.loading === 'succeeded' });
    }, [saveReuse.loading]);

    return {
        reuse,
        setReuse,
        refetch,
        save,

        error: error || saveReuse.error,
        isPending: loading === 'pending' || saveReuse.loading === 'pending'
    };
}
