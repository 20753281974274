import { ReuseSaver } from '../Reuse/ReuseSaver';

export const getEmptyReuseSaver = (): ReuseSaver => {
    const emptyReuse: ReuseSaver = {
        id: undefined,
        promotionId: null,
        ler: null,
        date: new Date().toISOString().split('T')[0],
        weight: null,
        volume: null,
        observations: '',
        reuseDoc: null
    };

    return emptyReuse;
};
