import fetcher from 'lib/fetcher';
import { JsonSchema } from 'modules/shared';
import { csvFieldsAdapter } from 'modules/waste-register/domain/export/csvFieldsAdapter';
import { WasteRegisterApi } from 'modules/waste-register/domain/WasteRegisterApi';
import { WasteRegisterComputed } from 'modules/waste-register/domain/WasteRegisterComputed';
import { WasteRegisterCreator } from 'modules/waste-register/domain/WasteRegisterCreator';
// import { mockWasteRegisterApi } from './mockWasteRegisterApi';

export const httpWasteRegisterApi: WasteRegisterApi = {
    async findByCriteria(criteria) {
        const params = {
            'promotionId.in': criteria.promotionIds?.join(','),
            page: criteria.page,
            size: criteria.size,
            sort: 'movementDate,desc'
        };

        const { data, headers } = await fetcher.get('/api/waste-registers', { params });
        const count = parseInt(headers['x-total-count']);

        if (count === undefined || count === null) throw new Error('Invalid count from "x-total-count" header');

        const wasteRegisters = data.content?.wasteRegisters;

        return { total: count, wasteRegisters };
    },

    async findExtendedById(payload) {
        if (!payload.id) return;
        const { data } = await fetcher.get(`/api/waste-registers/${payload.id}`);
        const register = data.content;
        return register;
    },

    async findDIByWasteRegisterId(payload) {
        const response = await fetcher.get(`/api/waste-registers/${payload.id}/public`);
        return { ...response.data.content, observation: response.data.content.observation };
    },

    async save(wasteRegister) {
        const body: WasteRegisterCreator = {
            id: wasteRegister?.id,
            flowId: wasteRegister?.flow?.id,
            movementDate: wasteRegister?.movementDate,
            observations: wasteRegister?.observation,
            weight: wasteRegister?.weight || null,
            volume: wasteRegister?.volume || null,
            carrierLerId: wasteRegister?.carrier?.carrierLers?.[0]?.id,
            ...(Object.values(wasteRegister?.extra || {}).length > 0 ? { extra: wasteRegister.extra } : {})
        };

        await fetcher.put(`/api/waste-registers`, body);
        return wasteRegister;
    },

    async downloadCsv(criteria) {
        /**
         * Move this to backend as a new endpoint
         * /api/waste-registers/export
         */
        const size = 999;
        const { total, wasteRegisters: firstPageResults } = await this.findByCriteria({ ...criteria, page: 0, size });

        // Retrives the rest of the pages
        const restPagesCount = total > size ? Math.ceil((total - size) / size) : 0;
        const restPages = Array.from({ length: restPagesCount }, (_, index) => index + 1);

        const restPagesResults = await Promise.all(
            restPages.map((page) => this.findByCriteria({ ...criteria, page, size }).then((r) => r.wasteRegisters))
        );

        const wasteRegisters = [firstPageResults, ...restPagesResults].flat();

        const header = csvFieldsAdapter(wasteRegisters[0])
            .map(([header]) => header)
            .join(',');

        const data = wasteRegisters.map((wr) =>
            csvFieldsAdapter(wr)
                .map(([, value]) => value?.toString().replace(',', ' '))
                .join(',')
        );

        const csv = [header, ...data].join('\n');

        // Conforms Blob and download
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Registros de residuo - ${new Date().toLocaleString()}.csv`;
        a.click();
    },

    async findSaveSchemaByPromotionId(payload) {
        const { data } = await fetcher.get(`api/waste-registers/promotion/${payload.promotionId}/schema`);
        const schema: JsonSchema = data.content?.schema;
        return schema;
    },
    async findComputedSchemaByPromotionId(payload) {
        const { data } = await fetcher.get(`/api/waste-registers-extended/promotion/${payload.promotionId}/schema`);
        const schema: JsonSchema = data?.content?.schema;

        return schema;
    },
    async findComputedByCriteria(payload) {
        const { data } = await fetcher.get(`/api/waste-registers-extended/promotion/${payload.promotionIds?.[0]}`);
        const wasteRegisterComputed: WasteRegisterComputed[] = data?.content;
        if (!wasteRegisterComputed) return [];

        /* LIMITACIÓN BACK: deben devolver -1 en lugar de null para que no les casque con el JSON Schema */
        const replaceNegativeOneWithNull = (data) => {
            // Si es un array, aplicar la función a cada elemento
            if (Array.isArray(data)) return data.map(replaceNegativeOneWithNull);

            // Si es un objeto, recorrer sus claves y valores
            if (data !== null && typeof data === 'object')
                return Object.fromEntries(
                    Object.entries(data).map(([key, value]) => [key, replaceNegativeOneWithNull(value)])
                );

            // Si el valor es exactamente -1, reemplazarlo por null
            return data === -1 ? null : data;
        };

        return replaceNegativeOneWithNull(wasteRegisterComputed);
    },
    async remove(id) {
        await fetcher.delete(`/api/waste-registers/${id}`);
    }
};
