import { useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import IconTooltip from 'components/BasicComponents/Tooltips/IconTooltip';
import { KPICard } from 'features/KPIs';
import { Widget } from 'features/KPIs/types/Widget';
import useMandatoryFlowsWidget from 'features/KPIs/widgets/useMandatoryFlowsWidget';
import useMixWidget from 'features/KPIs/widgets/useMixWidget';
import useValorizationWidget from 'features/KPIs/widgets/useValorizationWidget';
import { usePromotion } from 'modules/promotion';
import { useGoals } from 'modules/promotion-kpis';
import { PromotionKPIs } from 'modules/promotion-kpis/domain/PromotionKPIs';
import { useState } from 'react';
import KPIGoal from '../KPIGoal/KPIGoal';
import { SimpleKPIGoalValue } from '../KPIGoalValue';
import { MandatoryFlowsKPIGoalValue } from '../KPIGoalValue/MandatoryFlowsKPIGoalValue';
import './KPIGoals.scss';

interface KPIGoalsProps {
    kpi: PromotionKPIs;
}

const KPIGoals = (props: KPIGoalsProps) => {
    const [t] = useTranslation('KPIsGoalSettings');
    const [{ promotion }] = usePromotion();
    const [stickyCard, setStickyCard] = useState(false);

    const { data: goals, loading, error, refetch } = useGoals({ kpi: props.kpi, promotionId: promotion?.id || NaN });

    const KPIGoalValue =
        (props.kpi === PromotionKPIs.mandatoryFlows && MandatoryFlowsKPIGoalValue) ||
        // default value component
        SimpleKPIGoalValue;

    const params = {
        promotionId: promotion?.id.toString() || '',
        promotionProgress: promotion?.progress || 0,
        // Workaround to force the widget to update when the goals change
        goals: (goals?.length || 0) > 0 ? JSON.stringify(goals) : ''
    };

    const kpis: { [key in string]: Widget } = {
        valorization: useValorizationWidget,
        mix: useMixWidget,
        'mandatory-flows': useMandatoryFlowsWidget
    };

    const widget = kpis[props.kpi](params);

    return (
        <>
            <header className={`KPIGoals__header ${stickyCard ? 'KPIGoals__header--sticky' : ''}`}>
                <KPICard params={params} widget={widget}>
                    <label className="KPIGoals__checkbox">
                        <input
                            checked={stickyCard}
                            onChange={() => setStickyCard(!stickyCard)}
                            type="checkbox"
                            placeholder="Search..."
                        ></input>
                        <small>fijar tarjeta</small>
                    </label>
                </KPICard>
            </header>

            <SimpleCard
                className="KPIGoals"
                title={t(`kpi.${props.kpi}.title`)}
                subtitle={t(`kpi.${props.kpi}.subtitle`)}
            >
                <table className="KPIGoals__table" data-testid={`KPIGoals__${props.kpi}`}>
                    <thead>
                        <tr>
                            <th>
                                {t('labels.scope')} &nbsp; <IconTooltip size={16} msg={t('descriptions.scope')} />
                            </th>
                            <th>
                                {t('labels.severity')} &nbsp; <IconTooltip size={16} msg={t('descriptions.severity')} />
                            </th>
                            <th>
                                {t('labels.progress')} &nbsp; <IconTooltip size={16} msg={t('descriptions.progress')} />
                            </th>
                            <th>
                                {t(`labels.value`)} &nbsp; <IconTooltip size={16} msg={t('descriptions.value')} />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    {goals && (
                        <tbody>
                            <KPIGoal
                                kpi={props.kpi}
                                onSave={() => {
                                    refetch();
                                }}
                                KPIGoalValue={KPIGoalValue}
                            />
                            {goals?.map((goal, index) => {
                                const prevGoal = goals[index - 1];
                                return (
                                    <KPIGoal
                                        className={
                                            prevGoal?.scope?.name !== goal?.scope?.name ? 'KPIGoals--change-scope' : ''
                                        }
                                        key={goal.uuid}
                                        kpi={props.kpi}
                                        goal={goal}
                                        KPIGoalValue={KPIGoalValue}
                                        onRemove={() => {
                                            refetch();
                                        }}
                                        onSave={() => {
                                            refetch();
                                        }}
                                    />
                                );
                            })}
                        </tbody>
                    )}
                </table>
                {loading === 'failed' && <ErrorCard className="KPIGoals__error" error={error} />}
            </SimpleCard>
        </>
    );
};

export default KPIGoals;
