import fetcher from 'lib/fetcher';

import { LerCore } from 'modules/ler/domain/LerCore';
import { Ler } from '../../domain/Ler';
import { LerApi } from '../../domain/LerApi';

export const httpLerApi: LerApi = {
    async findLerByCriteria(criteria) {
        const { promotionId } = criteria || {};

        if (promotionId !== undefined) {
            const { data } = await fetcher.get(`/api/lers/project/${promotionId}`, {
                params: { 'codLer.contains': criteria?.search }
            });
            const ler: Ler[] = data.content.map((item) => ({
                id: item.id,
                code: item.code,
                type: item.lerKind
            }));

            return ler;
        }

        return [];
    },
    async findReuseLers(search) {
        const criteria = {
            'hasPgcl.equals': true,
            'valorizationCode.equals': 'RU0',
            size: 999,
            sort: 'codigoLER,asc',
            'lerType.contains': search,
            'codigoLER.contains': search
        };

        const { data } = await fetcher('/api/lers', { params: criteria });

        const lers: Array<Ler & { id: number }> = data?.map((item: LerCore) => ({
            id: item.id,
            code: item.codigoLER,
            type: item.lerType.lerKind
        }));

        return lers;
    }
};
