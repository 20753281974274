import { PropsWithChildren, ReactElement, useEffect, useState, useRef } from 'react';
import './DropdownList.scss';

interface DropdownListProps {
    items: Array<ReactElement<any> | null>;
    closeOnItemClick?: boolean;
}

const DropdownList = ({ items, children, closeOnItemClick = true }: PropsWithChildren<DropdownListProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [allChildrenEmpty, setAllChildrenEmpty] = useState(false);
    const dropdownRef = useRef<HTMLInputElement>(null);

    const toggle = (e, _isOpen: boolean) => {
        e.preventDefault();
        // stop propagation is required as it avoids row click, but it also stops click event -> addEventListener won't detect anything
        e.stopPropagation();

        // after propagation is avoided, we dispatch click event and this will close the other open dropdowns
        document.dispatchEvent(new Event('click'));
        setIsOpen(_isOpen);
    };

    useEffect(() => {
        document.addEventListener('click', clickOutside);
        return () => document.removeEventListener('click', clickOutside);
    }, [isOpen]);

    /**
     * To avoid rendering empty listings when components have internal conditions that prevent them from being rendered,
     * we check that the nodes do not remain empty and if they do, we do not render the dropdown.
     */
    useEffect(() => {
        if (!dropdownRef.current) return;
        const list = dropdownRef.current?.querySelectorAll('li');
        if (!list) return;
        const allEmpty = [...list].map((item) => item.childElementCount).every((childrenCount) => childrenCount === 0);

        setAllChildrenEmpty(allEmpty);
    }, [items]);

    const clickOutside = (event) => {
        if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const modifiers = [isOpen ? 'DropdownList--is-open' : ''];

    if (allChildrenEmpty) return null;

    return (
        <div className={`DropdownList ${modifiers.join(' ')}`}>
            <div role="button" onClick={(e) => toggle(e, true)}>
                {children}
            </div>
            <div ref={dropdownRef}>
                <ul className="DropdownList__list">
                    {items.map((item, index) =>
                        item !== null ? (
                            <li
                                role="button"
                                key={index}
                                className="DropdownList__item"
                                onClick={(e) => closeOnItemClick && toggle(e, false)}
                            >
                                {item}
                            </li>
                        ) : null
                    )}
                </ul>
            </div>
        </div>
    );
};

export default DropdownList;
