import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'assets/iconos/kpis/mandatory-flows-details-skeleton.svg';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import WasteManagementChart from 'features/waste-management/WasteManagementChart';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useMandatoryFlowsDetails } from 'modules/promotion-kpis';
import './MandatoryFlowsDetails.scss';
import MandatoryFlowAlarms from './components/MandatoryFlowAlarms';
import MandatoryFlowSelect from './components/MandatoryFlowSelect';
import MandatoryFlowsOverview from './components/MandatoryFlowsOverview';

type LerTypeId = number;
type MandatoryFlowsDetailMode = 'overview' | 'EGR' | 'PGR' | LerTypeId;

type MandatoryFlowsDetailsProps = {
    promotionId: string;
};

const MandatoryFlowsDetails = (props: MandatoryFlowsDetailsProps) => {
    const [t] = useTranslation('kpis');
    const [, analytics] = useAnalyticsStore();
    const mandatoryFlows = useMandatoryFlowsDetails({ promotionId: props.promotionId });
    const { loading } = mandatoryFlows;

    const [mode, setMode] = useState<MandatoryFlowsDetailMode>('overview');

    const views = [
        { id: 'overview', label: t('mandatory-flows.overview.title') },
        { id: 'EGR', label: 'EGR' },
        { id: 'PGR', label: 'PGR' }
    ];

    const switchView = (value: any) => {
        if (!value) return;
        setMode(value);
        if (value === 'EGR') analytics.event(AnalyticsEvents.KPIS_MANDATORYFLOWS_DETAIL_EGR_REACHED);
        if (value === 'PGR') analytics.event(AnalyticsEvents.KPIS_MANDATORYFLOWS_DETAIL_PGR_REACHED);
    };

    if (loading !== 'succeeded')
        return (
            <div className={`MandatoryFlowsDetails MandatoryFlowsDetails--${loading}`}>
                <ErrorCard error={mandatoryFlows.error} />
                <object data={Skeleton} type="image/svg+xml">
                    <img src={Skeleton} />
                </object>
            </div>
        );

    return (
        <div className="MandatoryFlowsDetails">
            <p className="MandatoryFlowsDetails__subtitle">{t('mandatory-flows.subtitle')}</p>
            <MandatoryFlowAlarms promotionId={props.promotionId} mandatoryFlows={mandatoryFlows.data} />

            <header className="MandatoryFlowsDetails__header">
                <SegmentedControl
                    onChange={switchView}
                    value={typeof mode !== 'number' ? mode : null}
                    options={views}
                />
                <MandatoryFlowSelect
                    flows={
                        mandatoryFlows.data?.lerTypes.map((flow) => ({
                            id: `${flow.goalScope}-${flow.lerTypeId || 'default'}`,
                            name: flow.name
                        })) || []
                    }
                    value={typeof mode === 'number' ? mode : ''}
                    onChange={setMode}
                />
            </header>

            {mode === 'overview' && <MandatoryFlowsOverview mandatoryFlows={mandatoryFlows.data} />}

            {/* Provisionally here */}
            {(mode === 'EGR' || mode === 'PGR' || typeof mode === 'number') && (
                <WasteManagementChart
                    promotionId={props.promotionId}
                    mode={mode}
                    onBarClick={(value) => setMode(typeof value === 'number' ? value : 'overview')}
                />
            )}
        </div>
    );
};

export default MandatoryFlowsDetails;
