import { StakeholderInfo, Traceability } from '../../../domain/Traceability/Traceability';

type Souces = {
    data: TraceabilityApiData;
    di?: DocumentApiData;
    finalTraceability?: FinalTraceabilityApiData;
};

export const traceabilityAdapter = ({ data, di, finalTraceability }: Souces): Traceability => {
    const traceability: Traceability = {
        id: data.id,
        updateDate: data.updateDate,
        updatedBy: data.updatedBy,
        creationDate: data.creationDate,
        createdBy: data.createdBy,
        date: data.movementDate,
        status: data.status as Traceability['status'],
        observations: data.observations,
        process: {
            valorizationCode: data.valorizationCode,
            description: data.valorizationDescription
        },
        di: {
            id: di?.id || NaN,
            num: data.numDi || null,
            url: di?.url || null,
            hasDi: !!data.diUrl
        },
        cost: data.cost,
        ler: {
            code: data.lerCode,
            desc: data.lerDescription,
            id: data.lerId,
            kind: data.lerTypeName,
            typeId: parseInt(data.lerTypeId),
            isDangerous: data.lerTypeDangerous,
            isLands: data.lerTypeLands
        },
        valorizationPercent: data.valorizationPercent,
        knownValorization: data.knownValorization,
        volume: data.volume,
        weight: data.weight,
        wasteFlowId: data.wasteFlowId,
        wasteManager: stakeholderInfoAdapter(data.wasteManager),
        carrier: { ...stakeholderInfoAdapter(data.carrier), vehiclePlate: data.carrier?.vehiclePlate },
        transferOperator: stakeholderInfoAdapter(data.transferOperator),
        project: { ...data.project, address: projectAddressAdapter(data.project.address) },

        canDelete: data.canDelete,
        canEdit: data.canEdit,
        /** ATM, edit and create operations has same permissions */
        canCreate: data.canEdit,

        finalTraceability,

        isReuse: data.valorizationCode === 'RU0'
    };

    /** TODO: Unknown valorization percent should change to "null" */
    if (traceability.valorizationPercent === 1) {
        traceability.valorizationPercent = null;
    }

    if (traceability.knownValorization === 1) {
        traceability.knownValorization = null;
    }

    return traceability;
};

const projectAddressAdapter = (legacyAddress: ApiAdress): Address => {
    const newAddress: Address = {
        address: legacyAddress.direccion,
        city: {
            name: legacyAddress.municipio.nombre,
            code: legacyAddress.municipio.codigo
        },
        postalCode: legacyAddress.postalCode,
        province: {
            name: legacyAddress.municipio.provincia.nombre,
            code: legacyAddress.municipio.provincia.codigo
        }
    };

    return newAddress;
};

const stakeholderInfoAdapter = (data: StakeholderInfoApiData): StakeholderInfo => {
    if (!data) return {} as StakeholderInfo;

    return {
        addresses: data.address ? [data.address] : [],
        email: data.email,
        id: data.id,
        name: data.name,
        nif: data.nif,
        phone: parseInt(data.phone),
        nima: data.nima
    };
};

export interface DocumentApiData {
    id: number;
    name: string;
    url: string;
    remarks: null;
    date: string;
    dateUpdate: string;
    type: {
        id: number;
        name: string;
        unique: boolean;
        uploadable: boolean;
        visible: boolean;
    };
    mimeType: null;
}

export interface TraceabilityApiData {
    id: number;
    wasteRegisterId: string | null;
    numDi: string;
    valorizationCode: string;
    valorizationDescription: string;
    status: string;
    lerId: string;
    lerCode: string;
    lerDescription: string;
    lerTypeId: string;
    lerTypeName: string;
    lerTypeDangerous: boolean;
    lerTypeLands: boolean;
    valorizationPercent: number;
    knownValorization: number;
    weight: number;
    volume: number;
    cost: number;
    observations: string;
    diUrl: string;
    /** @format ISO */
    creationDate: string;
    createdBy: string | null;
    /** @format ISO */
    updateDate: string;
    updatedBy: string | null;
    movementDate: string;
    wasteFlowId: number;
    wasteManager: StakeholderInfoApiData;
    carrier: StakeholderInfoApiData & { vehiclePlate: string };
    transferOperator: StakeholderInfoApiData;
    project: {
        id: number;
        enterpriseName: string;
        enterpriseCif: string;
        name: string;
        nima: string;
        address: ApiAdress;
    };
    canDelete: boolean;
    canEdit: boolean;
}

interface StakeholderInfoApiData {
    id: string;
    name: string;
    nima: string;
    nif: string;
    address: Address;
    phone: string;
    email: string;
}

interface Address {
    address: string;
    postalCode: string;
    city: City;
    province: City;
}

interface City {
    code: string;
    name: string;
}

interface FinalTraceabilityApiData {
    finalTraceability: FinalTraceabilityPointApiData[];
    valorizationPercentCocircular?: any;
}

interface FinalTraceabilityPointApiData {
    lerType: string;
    ler: string;
    treatment: string;
    valorizationPercentage: number;
    isfinalValorizator: boolean;
    wasteManagerName: string;
    id: number;
}

interface ApiAdress {
    id: string;
    direccion: string;
    tipo: Tipo;
    municipio: Municipio;
    postalCode: string;
}

interface Municipio {
    id: number;
    nombre: string;
    codigo: string;
    provincia: Provincia;
}

interface Provincia {
    id: number;
    nombre: string;
    codigo: string;
    comunidadAutonoma: ComunidadAutonoma;
}

interface ComunidadAutonoma {
    id: number;
    nombre: string;
    codigo: string;
}

interface Tipo {
    id: number;
    nombre: string;
}
