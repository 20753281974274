import { PropsWithChildren, useMemo, useRef } from 'react';

import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { Widget, WidgetProps } from 'features/KPIs/types/Widget';
import './KPICard.scss';

type KPICardProps = {
    params: WidgetProps & { [key: string]: any };
    widget: ReturnType<Widget>;
};

const KPICardContent = (props: KPICardProps) => {
    return (
        <>
            <div className="KPICard__tile">{<props.widget.Tile />}</div>
            {/* Workaround: prevent the Detail component from being re-rendered when the parent component is re-rendered */}
            {useMemo(
                () => (
                    <div className="KPICard__detail">{<props.widget.Detail />}</div>
                ),
                [JSON.stringify(props)]
            )}
        </>
    );
};

const KPICard = ({ children, ...props }: PropsWithChildren<KPICardProps>) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <SimpleCard className="KPICard" subtitle={'asdf'}>
            {children}
            <div id="KPICard__container">
                <div ref={ref}>
                    <KPICardContent {...props} />
                </div>
            </div>
        </SimpleCard>
    );
};

export default KPICard;
