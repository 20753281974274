import QueryString from 'qs';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useQuery<Params extends { [key: string]: any }>() {
    const { search: queryString } = useLocation();
    const history = useHistory();

    const params = useMemo(() => {
        return QueryString.parse(queryString.replace('?', '')) as Params;
    }, [queryString]);

    const push = (params: Params, config = { mode: 'merge' as 'merge' | 'replace' }) => {
        const currentParams = QueryString.parse(queryString.replace('?', ''));

        const newParams =
            (config.mode === 'replace' && params) ||
            (config.mode === 'merge' && { ...currentParams, ...params }) ||
            undefined;

        const search = createQueryString(newParams);
        history.push({ search });
    };

    const remove = (keys: string[]) => {
        setTimeout(() => {
            const currentParams = QueryString.parse(window.location.search.replace('?', ''));

            // deletes keys
            keys.forEach((key) => {
                currentParams[key] = undefined;
            });

            // updates url
            const search = createQueryString(currentParams, { skipNulls: false });
            history.push({ search });
        }, 500);
    };

    return { params, push, remove };
}

const createQueryString = (params?: { [key: string]: any }, config?: { skipNulls: boolean }) => {
    const { skipNulls = true } = config || {};
    return `?${QueryString.stringify(params || {}, { skipNulls })}`;
};
