import fetcher from '../../../lib/fetcher';

export const getWasteManagerPgcl = async (filter = '') => {
    const URL = `/api/waste-manager?hasPgcl.equals=true&sort=name,asc&valuationCode.notIn=O01,T01,N00,Pos00,A00,RU0&estadoId.notEquals=5604${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
