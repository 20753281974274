import { IconInfoCircle } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { wasteFlowOptionAdapter } from 'adapters/wasteFlowOptionAdapter';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { LegacyWasteFlow, useWasteFlow } from 'modules/waste-flow';
import './AutomaticReadingPreselector.scss';

export type AutomaticReadingPreselectorProps = {
    onWasteFlowSelected?: (wasteFlow?: LegacyWasteFlow) => void;
};

const AutomaticReadingPreselector = (props: AutomaticReadingPreselectorProps) => {
    const [t] = useTranslation('automaticReading');
    const [, analytics] = useAnalyticsStore();
    const [, , { api }] = useWasteFlow();
    const [{ promotion }] = usePromotion();

    const [loadPpgclOptions] = useAsyncOptions({
        request: ({ search, page }) => api.legacyGetProjectWasteFlows({ promotionId: promotion?.id, search, page }),
        adapter: (wasteFlow, search) => wasteFlowOptionAdapter(wasteFlow, search)
    });

    const [wasteFlow, setWasteFlow] = useState<LegacyWasteFlow>();

    useEffect(() => {
        props.onWasteFlowSelected?.(wasteFlow);
        if (wasteFlow) analytics.event(AnalyticsEvents.AUTOMATIC_READING_PRESELECT_FLOW);
    }, [wasteFlow]);

    return (
        <>
            <div className={`AutomaticReadingPreselector`}>
                <SelectAutocomplete
                    name="wasteFlow"
                    label={t('preselector.label')}
                    placeholder={t('preselector.placeholder')}
                    loadOptions={loadPpgclOptions}
                    value={wasteFlow ? wasteFlowOptionAdapter(wasteFlow) : undefined}
                    onChange={(option) => setWasteFlow((option as SelectOption)?.value as LegacyWasteFlow)}
                />

                {wasteFlow && (
                    <AlertCard className="AutomaticReadingPreselector__info" icon={<IconInfoCircle size={20} />}>
                        <Trans
                            i18nKey="automaticReading:preselector.info"
                            values={{ ler: wasteFlow.ler.code }}
                            components={{ b: <strong /> }}
                        />
                    </AlertCard>
                )}
            </div>
        </>
    );
};

export default AutomaticReadingPreselector;
