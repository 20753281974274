import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';

export const createPromotionTransferOperator = ({ promotion }) => {
    const mainEnterprise = legacyEnterpriseAdapter(promotion?.relatedEnterprises);

    return {
        id: mainEnterprise?.id,
        name: mainEnterprise?.nombreComercial,
        cif: mainEnterprise?.cif || '',
        nima: promotion.nima
    };
};

export const createWasteManagerTransferOperator = ({ wasteManager }) => {
    return {
        id: wasteManager.id,
        name: wasteManager.empresa,
        cif: wasteManager.identificacionFiscal,
        nima: wasteManager.nIMA
    };
};
