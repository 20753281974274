import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { useLerStore } from 'modules/ler';
import { ReuseSaver } from 'modules/traceabilities/domain/Reuse/ReuseSaver';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';

type ReuseLerSelectorProps = {
    onChange: (ler: ReuseSaver['ler']) => void;
    value?: ReuseSaver['ler'];
    disabled: boolean;
};

const lerToOptionAdapter = (ler) => ({
    value: ler.code,
    label: ler.code + ' - ' + ler.type,
    ler
});

const ReuseLerSelector = (props: ReuseLerSelectorProps) => {
    const [t] = useTranslation();
    const [, , { api }] = useLerStore();

    const [loadLerOptions] = useAsyncOptions({
        removePagination: true,
        adapter: lerToOptionAdapter,
        request: ({ search }) => api.findReuseLers(search)
    });

    return (
        <Tooltip msg={props.disabled ? 'No se permite editar el código LER' : ''}>
            <SelectAutocomplete
                name="ler"
                label={t('ler.code')}
                loadOptions={loadLerOptions}
                defaultOptions={false}
                value={props.value ? lerToOptionAdapter(props.value) : undefined}
                onChange={(option) => {
                    if (Array.isArray(option)) return;
                    props.onChange?.(option?.ler);
                }}
                disabled={props.disabled}
            />
        </Tooltip>
    );
};

export default ReuseLerSelector;
