import { IconX } from '@tabler/icons';
import { useState } from 'react';
import Modal from 'react-modal';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import AutomaticReadingAiBadge from 'features/automatic-reading/components/AutomaticReadingAiBadge';
import AutomaticReadingUpload from 'features/automatic-reading/components/AutomaticReadingUpload/AutomaticReadingUpload';
import { usePromotion } from 'modules/promotion';
import './TraceabilityUpload.scss';

interface TraceabilityUploadProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const TraceabilityUpload = ({ isOpen, setIsOpen }: TraceabilityUploadProps) => {
    const [{ promotion }] = usePromotion();
    const [lockModal, setLockModal] = useState(false);

    const customStyles = {
        overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(136, 136, 136, 0.75)',
            display: 'flex',
            justifyContent: 'center'
        },
        content: {
            overflow: 'hidden',
            overflowY: 'hidden',
            position: 'relative',
            top: '17.5vh'
        }
    };

    const close = () => {
        if (lockModal) return;
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            style={customStyles}
            ariaHideApp={false}
            className="TraceabilityUpload"
        >
            <header className="TraceabilityUpload__header">
                <div className="TraceabilityUpload__title">
                    <h2>Crea traslados a partir de DIs</h2>

                    <AutomaticReadingAiBadge />
                </div>
                <div className="TraceabilityUpload__close" role="button" onClick={close}>
                    <IconX />
                </div>
            </header>
            <AutomaticReadingUpload
                // Prevents modal from closing while uploading
                onUploadStart={() => setLockModal(true)}
                onUploadEnd={() => {
                    setLockModal(false);
                    setIsOpen(false);
                }}
            />
            <div>
                <p className="TraceabilityUpload__moreOptions">O también puedes...</p>
                <div className="TraceabilityUpload__buttons">
                    <BasicButton
                        type="link"
                        color="white"
                        text="Crear un traslado a mano"
                        to={`/promotion/${promotion?.id}/traceabilities/create`}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <BasicButton
                        type="link"
                        color="white"
                        text="Crear una reutilización"
                        to={`/promotion/${promotion?.id}/traceabilities/reuse/create`}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default TraceabilityUpload;
