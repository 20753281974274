import { LegacyCarrier } from 'modules/carrier';
import { Ler } from 'modules/traceabilities';
import { LegacyWasteFlow } from 'modules/waste-flow';
import { WasteManager } from 'modules/waste-manager';
import './WasteFlowInfo.scss';
import { Trans, useTranslation } from 'react-i18next';

type WasteFlowInfoProps = {
    flow?: LegacyWasteFlow | null;
    ler?: Ler;
    manager?: WasteManager;
    carrier?: LegacyCarrier | null;
};

const WasteFlowInfo = (props: WasteFlowInfoProps) => {
    const [t] = useTranslation();
    if (!props.flow)
        return (
            <div className="WasteFlowInfo" data-testid="WasteFlowInfo">
                {t('wasteFlowInfo.shouldSelect')}
            </div>
        );

    const { treatment, valuationPercentage, finalTreatment, ler, destination } = props.flow;
    return (
        <div className="WasteFlowInfo" data-testid="WasteFlowInfo">
            <div className="WasteFlowInfo__pill">
                <Trans
                    i18nKey="wasteFlowInfo.treatment"
                    components={{ b: <strong /> }}
                    values={{
                        treatment: treatment.code,
                        valorization:
                            valuationPercentage !== null && valuationPercentage !== undefined
                                ? `${valuationPercentage}%`
                                : t('wasteFlowInfo.unknown'),
                        type: treatment.type || ''
                    }}
                />
            </div>

            {finalTreatment && (
                <div className="WasteFlowInfo__pill">
                    <Trans
                        i18nKey="wasteFlowInfo.finalTreatment"
                        components={{ b: <strong /> }}
                        values={{
                            treatment: finalTreatment.code,
                            valorization:
                                finalTreatment.valuationPercentage !== null &&
                                finalTreatment.valuationPercentage !== undefined
                                    ? `${finalTreatment.valuationPercentage}%`
                                    : t('wasteFlowInfo.unknown'),
                            type: finalTreatment.type || ''
                        }}
                    />
                </div>
            )}

            {(ler.dangerous !== undefined || ler.state !== undefined) && (
                <div className="WasteFlowInfo__pill">
                    <Trans
                        i18nKey="wasteFlowInfo.waste"
                        components={{ b: <strong /> }}
                        values={{
                            state: ler.state,
                            dangerous: ler.dangerous ? t('wasteFlowInfo.dangerous') : t('wasteFlowInfo.nonDangerous')
                        }}
                    />
                </div>
            )}

            {destination && (
                <div className="WasteFlowInfo__pill">
                    <Trans
                        i18nKey="wasteFlowInfo.destination"
                        components={{ b: <strong /> }}
                        values={{
                            km: `${destination.distance}km`,
                            destination: destination.municipality
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default WasteFlowInfo;
