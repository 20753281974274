export const getObjFilter = (formValues) => {
    return [
        {
            label: 'ASC_DATE',
            apiUrl: '&sort=fechaActividad,asc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'DESC_DATE',
            apiUrl: '&sort=fechaActividad,desc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'fechaDesde',
            apiUrl: `&fechaActividad.greaterThanOrEqual=${formValues?.fechaDesde}`,
            typeOf: 'FILTER'
        },
        {
            label: 'fechaHasta',
            apiUrl: `&fechaActividad.lessThanOrEqual=${formValues?.fechaHasta}`,
            typeOf: 'FILTER'
        },
        {
            label: 'estadoActividad',
            apiUrl: `&estadoActividad.equals=${formValues?.estadoActividad}`,
            typeOf: 'FILTER'
        },
        {
            label: 'gestora',
            apiUrl: `&gestoraId.equals=${formValues?.gestora?.id}`,
            typeOf: 'FILTER'
        }
    ];
};

export const createFilterWithObject = (values, objFilter) => {
    let createFilter = '';
    for (let i in values) {
        if (values[i]) {
            if (values[i].typeOf === 'SORT') {
                const obj = objFilter.find((filter) => filter.label === values[i].label);
                createFilter += obj.apiUrl;
            } else {
                const obj = objFilter.find((filter) => filter.label === i);
                createFilter += obj.apiUrl;
            }
        }
    }
    return createFilter;
};

export const activityStatus = [
    { value: 'ABIERTO', label: 'open' },
    { value: 'EN_PROGRESO', label: 'inProgress' },
    { value: 'DETENIDO', label: 'stopped' },
    { value: 'INCOMPLETA', label: 'incompleted' },
    { value: 'CERRADO', label: 'closed' },
    { value: 'BORRADOR', label: 'draft' }
];

export const constructionSiteStatus = [
    { value: 'PLANIFICADA', label: 'planned' },
    { value: 'BORRADOR', label: 'draft' },
    { value: 'CERRADO', label: 'closed' }
];

export const INCIDENCE_ID = 389651;
