import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';

import { traceabilitiesStore } from '../../application/traceabilitiesStore';

const traceabilitiesContext = contextStoreAdapter(traceabilitiesStore)({ name: 'TraceabilitiesStore' });

export const TraceabilitiesProvider = traceabilitiesContext.Provider;
export const useTraceabilities = traceabilitiesContext.useConsumer;

export const useTraceabilityToEdit = traceabilitiesContext.createQueryHook(({ api }) =>
    api.getSaverExtendedById.bind(api)
);
export const useSaveTraceability = traceabilitiesContext.createMutationHook(({ api }) => api.save.bind(api));
export const useTraceabilitySaveExtraSchema = traceabilitiesContext.createQueryHook(({ api }) =>
    api.getSaveExtraSchemaByPromotionId.bind(api)
);

export const useRemoveTraceability = traceabilitiesContext.createMutationHook(({ api }) =>
    api.removeTraceability.bind(api)
);

export const useSaveReuse = traceabilitiesContext.createMutationHook(({ api }) => api.saveReuse.bind(api));

export const useGetReuseDetail = traceabilitiesContext.createQueryHook(({ api }) => api.getReuseDetail.bind(api));
