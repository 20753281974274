import { IconPennant, IconUrgent } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { GoalSeverityLevels, PromotionKPIs } from 'modules/promotion-kpis';
import { KPIGoalProps } from '../KPIGoal';

export const useGoalSeverityOptions = (props: KPIGoalProps) => {
    const loadSeverityOptions = async () => ({
        hasMore: false,
        options: [
            //
            severityOptionAdapter({ kpi: props.kpi, severity: GoalSeverityLevels.Critical }),
            severityOptionAdapter({ kpi: props.kpi, severity: GoalSeverityLevels.Warning })
        ]
    });

    return { loadSeverityOptions };
};

export const severityOptionAdapter = (params: { severity: GoalSeverityLevels; kpi: PromotionKPIs }) => ({
    value: params.severity,
    label: <SeverityOption kpi={params.kpi} severity={params.severity} />
});

const SeverityOption = (props: { severity: GoalSeverityLevels; kpi: PromotionKPIs }) => {
    const [t] = useTranslation('KPIsGoalSettings');

    return (
        <div
            className={`KPIGoal__severity-option KPIGoal__severity-option--${props.severity}`}
            data-testid={`KPIGoal__severity-option--${props.severity}`}
        >
            {props.severity === GoalSeverityLevels.Critical && <IconUrgent size={16} stroke={1.5} />}
            {props.severity === GoalSeverityLevels.Warning && <IconPennant size={16} stroke={1.5} />}
            <span>{t(`kpi.${props.kpi}.severity.${props.severity}`)}</span>
        </div>
    );
};
