import { GridOptions } from 'ag-grid-community';
import { useMemo, useState } from 'react';

import ProgressBar from 'components/BasicComponents/Progress/ProgressBar/ProgressBar';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { useAuth } from 'modules/auth';
import { ComparativePromotion } from 'modules/comparative';
import { getPercentageStatus, getScoreStatus } from 'modules/comparative/domain/comparative.helpers';
import { useTranslation } from 'react-i18next';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import {
    ArrayCell,
    BooleanCell,
    GoalsSummaryCell,
    ObservationsCell,
    PromotionCell,
    StatusMetCell,
    TagsCell,
    UsersCell
} from '../components/ComparativeColumnComponents/ComparativeColumnComponents';
import { getGoalsSummaryText } from '../helpers/comparative.helpers';

type Options = GridOptions<ComparativePromotion>;

export type ObservationsInfo = {
    observations: ComparativePromotion['observations'];
    project: {
        id: ComparativePromotion['promotion']['id'];
        name: ComparativePromotion['promotion']['name'];
    };
};

/* Inspiration from: https://stackoverflow.com/a/51445435 */
const isFirstColumn = (cell) => {
    const { column } = cell;
    const { parent } = column;

    // maximum of 3 nested levels
    const firstColumnId =
        parent?.parent?.parent?.displayedChildren?.[0].displayedChildren?.[0].displayedChildren?.[0].colId ??
        parent?.parent?.displayedChildren?.[0].displayedChildren?.[0].colId ??
        parent?.displayedChildren?.[0].colId;

    return column.colId === firstColumnId;
};

const group = {};

const column = {
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    cellClass: (params) => {
        const cellType = params.colDef.cellDataType;
        const { value } = params;

        if (cellType === 'finalScore') {
            return `Comparative__finalScore Comparative--${getScoreStatus(value)}`;
        }

        if (cellType === 'percentage') {
            return `Comparative--${getPercentageStatus(value)}`;
        }
    },
    cellStyle: (params) => {
        const cellType = params.colDef.cellDataType;
        let extraStyles = {};

        if (['number', 'percentage', 'score', 'finalScore', 'boolean'].includes(cellType)) {
            extraStyles = { ...extraStyles, justifyContent: 'flex-end' };
        }

        if (isFirstColumn(params)) {
            extraStyles = { ...extraStyles, borderLeft: '1px solid rgb(205, 200, 218)' };
        }

        return extraStyles;
    }
};

export function useComparativeGrid() {
    const [t] = useTranslation('comparative');
    const { format } = useDateFormatter();
    const [{ isCoCircular }] = useAuth();
    const [openObservationsModal, setOpenObservationsModal] = useState(false);
    const [selectedObservationsInfo, setSelectedObservationsInfo] = useState<ObservationsInfo | undefined>(undefined);

    // By memoizing the options, we avoid re-creating the object every time the component re-renders to prevent Ag-Grid from re-rendering the entire grid
    const options: Options = useMemo(
        () => ({
            localeText: AG_GRID_LOCALE_ES,
            rowModelType: 'clientSide',
            dataTypeDefinitions: {
                percentage: {
                    extendsDataType: 'number',
                    baseDataType: 'number',
                    valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(value)}%`)
                },
                finalScore: {
                    extendsDataType: 'number',
                    baseDataType: 'number',
                    valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(value)}`)
                },
                score: {
                    extendsDataType: 'number',
                    baseDataType: 'number',
                    valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(value)}`)
                },
                number: {
                    extendsDataType: 'number',
                    baseDataType: 'number',
                    valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(value, 4)}`)
                },
                dateType: {
                    extendsDataType: 'dateString',
                    baseDataType: 'dateString',
                    valueFormatter: ({ value }) => (value == null ? '' : format(value, 'DD/MM/YY'))
                }
            },
            headerHeight: 40,
            groupHeaderHeight: 36,
            tooltipShowDelay: 500,
            columnDefs: [
                {
                    ...group,
                    headerName: t('promotion.title'),
                    field: 'promotion',
                    children: [
                        {
                            ...column,
                            field: 'promotion.name',
                            headerName: t('promotion.name'),
                            pinned: 'left',
                            floatingFilter: true,
                            width: 400,
                            cellRenderer: PromotionCell
                        },
                        {
                            ...column,
                            field: 'promotion.progress',
                            headerName: t('promotion.progress'),
                            pinned: 'left',
                            width: 180,
                            cellRenderer: (params) => (
                                <ProgressBar
                                    displayPercentageText={true}
                                    percentage={params?.value || 0}
                                    style={{ width: '100%' }}
                                />
                            )
                        }
                    ]
                },
                {
                    ...group,
                    field: 'protocol',
                    headerName: t('protocol.score'),
                    children: [
                        {
                            ...column,
                            field: 'protocol.score',
                            cellDataType: 'finalScore',
                            headerName: t('result'),
                            width: 140
                        },
                        {
                            ...column,
                            field: 'protocol.areas',
                            columnGroupShow: 'open',
                            headerName: t('areas'),
                            children: [
                                {
                                    ...column,
                                    field: 'protocol.areas.valorizables',
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => <BooleanCell {...params} />,
                                    headerName: t('valorizable'),
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.areas.dangerous',
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => <BooleanCell {...params} />,
                                    headerName: t('dangerous'),
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.areas.rsu',
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => <BooleanCell {...params} />,
                                    headerName: t('rsu'),
                                    columnGroupShow: 'open',
                                    width: 130
                                }
                            ]
                        },
                        {
                            ...column,
                            field: 'protocol.signage',
                            columnGroupShow: 'open',
                            headerName: t('signage'),
                            children: [
                                {
                                    ...column,
                                    field: 'protocol.signage.valorizables',
                                    headerName: t('valorizable'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.signage.dangerous',
                                    headerName: t('dangerous'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.signage.rsu',
                                    headerName: t('rsu'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                }
                            ]
                        },
                        {
                            ...column,
                            field: 'protocol.treatmentContracts',
                            headerName: t('protocol.existTreatmentContracts'),
                            columnGroupShow: 'open',
                            children: [
                                {
                                    ...column,
                                    field: 'protocol.treatmentContracts.valorizables',
                                    headerName: t('valorizable'),
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => (
                                        <BooleanCell {...params} tooltip="protocol.treatmentContracts" />
                                    ),
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.treatmentContracts.dangerous',
                                    headerName: t('dangerous'),
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => (
                                        <BooleanCell {...params} tooltip="protocol.treatmentContracts" />
                                    ),
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'protocol.treatmentContracts.rsu',
                                    headerName: t('rsu'),
                                    cellDataType: 'boolean',
                                    cellRenderer: (params) => (
                                        <BooleanCell
                                            {...params}
                                            niceToHave={true}
                                            tooltip="protocol.treatmentContracts"
                                        />
                                    ),
                                    columnGroupShow: 'open',
                                    width: 130
                                }
                            ]
                        },
                        {
                            ...column,
                            field: 'protocol.existPgr',
                            headerName: t('protocol.existPgr'),
                            columnGroupShow: 'open',
                            cellDataType: 'boolean',
                            cellRenderer: (params) => <BooleanCell {...params} />,
                            width: 130
                        }
                    ]
                },
                {
                    ...column,
                    field: 'execution',
                    columnGroupShow: 'open',
                    headerName: t('execution.score'),
                    children: [
                        {
                            ...column,
                            field: 'execution.score',
                            headerName: t('result'),
                            cellDataType: 'finalScore',
                            width: 140
                        },
                        {
                            ...column,
                            field: 'execution.valorizables',
                            headerName: t('valorizable'),
                            columnGroupShow: 'open',
                            width: 130,
                            children: [
                                {
                                    ...column,
                                    field: 'execution.valorizables.purity',
                                    headerName: t('purity'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                }
                            ]
                        },
                        {
                            ...column,
                            field: 'execution.dangerous',
                            headerName: t('dangerous'),
                            columnGroupShow: 'open',
                            width: 130,
                            children: [
                                {
                                    ...column,
                                    field: 'execution.dangerous.purity',
                                    headerName: t('purity'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                },
                                {
                                    ...column,
                                    field: 'execution.dangerous.rainProtection',
                                    headerName: t('rainProtection'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 160
                                },
                                {
                                    ...column,
                                    field: 'execution.dangerous.waterproofTrays',
                                    headerName: t('waterproofTrays'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 160
                                }
                            ]
                        },
                        {
                            ...column,
                            field: 'execution.rsu',
                            headerName: t('rsu'),
                            columnGroupShow: 'open',
                            width: 130,
                            children: [
                                {
                                    ...column,
                                    field: 'execution.rsu.purity',
                                    headerName: t('purity'),
                                    cellDataType: 'percentage',
                                    columnGroupShow: 'open',
                                    width: 130
                                }
                            ]
                        }
                    ]
                },
                {
                    ...group,
                    headerName: t('goals.summary'),
                    field: 'goals',
                    children: [
                        {
                            ...column,
                            field: 'goals.summary',
                            headerName: t('result'),
                            width: 240,
                            // Setting value manually. now value === "Cumple objetivos" / "No cumple"...
                            // This way, filter will work and that text will be shown at csv export
                            valueGetter: ({ data }) => getGoalsSummaryText(data?.goals.summary),
                            // Do not retreive "value" bc we've changed it. Retrieve original data
                            cellRenderer: ({ data }) => GoalsSummaryCell(data?.goals.summary)
                        },
                        {
                            ...column,
                            field: 'goals.valorization',
                            headerName: t('goals.valorization'),
                            columnGroupShow: 'open',
                            width: 140,
                            cellDataType: 'number',
                            // valueGetter, ironically, sets value. This will be used at filters and csv
                            valueGetter: ({ data }) => Math.trunc(data?.goals.valorization.value || 0),
                            // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            cellRenderer: ({ data }: { data: ComparativePromotion | undefined }) =>
                                StatusMetCell({
                                    promotionId: data?.promotion.id || '',
                                    kpiKey: 'valorization',
                                    value: data?.goals.valorization.value,
                                    status: data?.goals.valorization.status || null,
                                    text:
                                        data?.goals.valorization.value == null
                                            ? '-'
                                            : `${Math.trunc(data?.goals.valorization.value)}%`
                                })
                        },
                        {
                            ...column,
                            field: 'goals.mix',
                            headerName: t('goals.mix'),
                            columnGroupShow: 'open',
                            width: 140,
                            cellDataType: 'number',
                            // valueGetter, ironically, sets value. This will be used at filters and csv
                            valueGetter: ({ data }) => Math.trunc(data?.goals.mix.value || 0),
                            // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            cellRenderer: ({ data }: { data: ComparativePromotion | undefined }) =>
                                StatusMetCell({
                                    value: data?.goals.mix.value,
                                    promotionId: data?.promotion.id || '',
                                    kpiKey: 'mix',
                                    status: data?.goals.mix.status || null,
                                    text: data?.goals.mix.value == null ? '-' : `${Math.trunc(data?.goals.mix.value)}%`
                                })
                        },
                        {
                            ...column,
                            field: 'goals.mandatoryFlows',
                            headerName: t('goals.mandatoryFlows'),
                            columnGroupShow: 'open',
                            cellDataType: 'number',
                            width: 140,
                            // valueGetter, ironically, sets value. This will be used at filters and csv
                            valueGetter: ({ data }) =>
                                `${data?.goals.mandatoryFlows.segregated}/${data?.goals.mandatoryFlows.total}`,
                            // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            cellRenderer: ({ data }: { data: ComparativePromotion | undefined }) =>
                                StatusMetCell({
                                    promotionId: data?.promotion.id || '',
                                    value: data?.goals.mandatoryFlows.total,
                                    kpiKey: 'mandatoryFlows',
                                    status: data?.goals.mandatoryFlows.status || null,
                                    text: `${data?.goals.mandatoryFlows.segregated}/${data?.goals.mandatoryFlows.total}`
                                }),
                            // Overwrite default sorting because format is string "x/y"
                            comparator: (...args) => {
                                const [, , nodeA, nodeB] = args;
                                const a = nodeA.data?.goals?.mandatoryFlows?.segregated || 0;
                                const b = nodeB.data?.goals?.mandatoryFlows?.segregated || 0;
                                return a - b;
                            }
                        },
                        {
                            ...column,
                            field: 'goals.treatmentContracts',
                            headerName: t('goals.treatmentContracts'),
                            columnGroupShow: 'open',
                            cellDataType: 'number',
                            width: 140,
                            // valueGetter, ironically, sets value. This will be used at filters and csv
                            valueGetter: ({ data }) =>
                                `${data?.goals.treatmentContracts.flowsWithContract}/${data?.goals.treatmentContracts.totalFlows}`,
                            // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            cellRenderer: ({ data }: { data: ComparativePromotion | undefined }) =>
                                StatusMetCell({
                                    promotionId: data?.promotion.id || '',
                                    value: data?.goals.treatmentContracts.totalFlows,
                                    kpiKey: 'treatmentContracts',
                                    status: data?.goals.treatmentContracts.status || null,
                                    text: `${data?.goals.treatmentContracts.flowsWithContract}/${data?.goals.treatmentContracts.totalFlows}`
                                }),
                            // Overwrite default sorting because format is string "x/y"
                            comparator: (...args) => {
                                const [, , nodeA, nodeB] = args;
                                const a = nodeA.data?.goals?.treatmentContracts?.flowsWithContract || 0;
                                const b = nodeB.data?.goals?.treatmentContracts?.flowsWithContract || 0;
                                return a - b;
                            }
                        },
                        {
                            ...column,
                            field: 'goals.traceabilityUpdate',
                            headerName: t('goals.traceabilityUpdate'),
                            cellDataType: 'number',
                            columnGroupShow: 'open',
                            width: 130,
                            // valueGetter, ironically, sets value. This will be used at filters and csv
                            valueGetter: ({ data }) => data?.goals.traceabilityUpdate.daysPassed, // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            // use "data" because "value" was modified by the above line (now "value" === string or number) and "data" is the original object
                            cellRenderer: ({ data }: { data: ComparativePromotion | undefined }) =>
                                StatusMetCell({
                                    promotionId: data?.promotion.id || '',
                                    value: data?.goals.traceabilityUpdate.daysPassed,
                                    kpiKey: 'traceabilityUpdate',
                                    status: data?.goals.traceabilityUpdate.status || null,
                                    text: `${data?.goals.traceabilityUpdate.daysPassed}`
                                })
                        }
                    ]
                },
                {
                    ...group,
                    headerName: t('observations'),
                    field: 'observations',
                    children: [
                        {
                            ...column,
                            field: 'observations.text',
                            headerName: t('observations.text'),
                            width: 350,
                            cellRenderer: (params) =>
                                ObservationsCell({
                                    text: params.value,
                                    isCoCircular,
                                    action: () => {
                                        setSelectedObservationsInfo({
                                            observations: params?.data?.observations,
                                            project: {
                                                id: params?.data?.promotion?.id,
                                                name: params?.data?.promotion?.name
                                            }
                                        });
                                        setOpenObservationsModal(true);
                                    }
                                })
                        },
                        {
                            ...column,
                            field: 'observations.date',
                            cellDataType: 'dateType',
                            headerName: t('observations.date'),
                            width: 100
                        },
                        {
                            ...column,
                            field: 'observations.user',
                            headerName: t('observations.user'),
                            width: 120
                        }
                    ]
                },
                {
                    ...group,
                    headerName: t('generatedWaste.title'),
                    field: 'generatedWaste',
                    children: [
                        {
                            ...column,
                            field: 'generatedWaste.fgr',
                            cellDataType: 'number',
                            headerName: t('generatedWaste.fgr'),
                            width: 165
                        },
                        {
                            ...column,
                            field: 'generatedWaste.egrOrPgrVsReal',
                            cellDataType: 'number',
                            headerName: t('generatedWaste.egrOrPgrVsReal'),
                            headerTooltip: t('generatedVsEgrOrPgrExplanation'),
                            valueFormatter: ({ value }) => (value == null ? '' : `${Math.trunc(value)}%`),
                            width: 165
                        },
                        {
                            ...column,
                            field: 'generatedWaste.totalTraceabilities',
                            headerName: t('generatedWaste.totalTraceabilities.title'),
                            children: [
                                {
                                    ...column,
                                    field: 'generatedWaste.totalTraceabilities.total',
                                    headerName: t('total'),
                                    cellDataType: 'number',
                                    width: 150
                                },
                                {
                                    ...column,
                                    field: 'generatedWaste.totalTraceabilities.valorizables',
                                    headerName: t('valorizable'),
                                    cellDataType: 'number',
                                    columnGroupShow: 'open',
                                    width: 140
                                },
                                {
                                    ...column,
                                    field: 'generatedWaste.totalTraceabilities.dangerous',
                                    headerName: t('dangerous'),
                                    cellDataType: 'number',
                                    columnGroupShow: 'open',
                                    width: 140
                                },
                                {
                                    ...column,
                                    field: 'generatedWaste.totalTraceabilities.land',
                                    headerName: t('lands'),
                                    cellDataType: 'number',
                                    columnGroupShow: 'open',
                                    width: 140
                                }
                            ]
                        }
                    ]
                },
                {
                    ...group,
                    headerName: t('promotion.other'),
                    field: 'promotion',
                    children: [
                        {
                            ...column,
                            field: 'promotion.owner',
                            headerName: t('promotion.owner'),
                            floatingFilter: true
                        },
                        {
                            ...column,
                            field: 'promotion.producer',
                            headerName: t('promotion.producer'),
                            floatingFilter: true
                        },
                        {
                            ...column,
                            field: 'promotion.managers',
                            headerName: t('promotion.managers'),
                            floatingFilter: true,
                            cellRenderer: ArrayCell,
                            filterValueGetter: (params) => params.data?.promotion.managers.map((m) => m.name).join(' ')
                        },
                        {
                            ...column,
                            field: 'promotion.users',
                            headerName: t('promotion.users'),
                            floatingFilter: true,
                            width: 180,
                            cellRenderer: UsersCell,
                            filterValueGetter: (params) =>
                                params.data?.promotion.users
                                    ?.map((u) => `${u.email} ${u.firstName} ${u.lastName}`)
                                    ?.join(' '),
                            comparator: (a, b) => {
                                if (!a || !b) return null;
                                return a.length > b.length ? 1 : -1;
                            }
                        },
                        {
                            ...column,
                            field: 'promotion.tags',
                            cellDataType: 'object',
                            headerName: t('promotion.tags'),
                            floatingFilter: true,
                            cellRenderer: TagsCell,
                            valueFormatter: (params) => params.value.map((tag: any) => tag.name).join(', ')
                        },
                        {
                            ...column,
                            field: 'promotion.nima',
                            headerName: t('promotion.nima'),
                            maxWidth: 140
                        },
                        {
                            ...column,
                            field: 'promotion.pem',
                            headerName: t('promotion.pem'),
                            cellDataType: 'number',
                            maxWidth: 140
                        },
                        {
                            ...column,
                            field: 'promotion.surface',
                            headerName: t('promotion.surface'),
                            cellDataType: 'number',
                            width: 150
                        },
                        {
                            ...column,
                            field: 'promotion.type',
                            headerName: t('promotion.type'),
                            floatingFilter: true
                        },
                        {
                            ...column,
                            field: 'promotion.startDate',
                            headerName: t('promotion.startDate'),
                            cellDataType: 'dateType',
                            maxWidth: 140
                        },
                        {
                            ...column,
                            field: 'promotion.endDate',
                            headerName: t('promotion.endDate'),
                            cellDataType: 'dateType',
                            maxWidth: 140
                        },
                        {
                            ...column,
                            field: 'promotion.province',
                            headerName: t('promotion.province'),
                            floatingFilter: true
                        },
                        {
                            ...column,
                            field: 'promotion.town',
                            headerName: t('promotion.town'),
                            floatingFilter: true
                        },
                        {
                            ...column,
                            field: 'promotion.cp',
                            headerName: t('promotion.cp'),
                            floatingFilter: true,
                            maxWidth: 120
                        },
                        {
                            ...column,
                            field: 'promotion.address',
                            headerName: t('promotion.address'),
                            floatingFilter: true,
                            width: 500 // AG Grid croppes out the text for some unknown reason... it's the last element so it doesn't matter if it has 500px
                        }
                    ]
                }
            ]
        }),
        [isCoCircular]
    );

    return {
        options,
        openObservationsModal,
        setOpenObservationsModal,
        selectedObservationsInfo
    };
}
