import { Redirect, Switch } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import Layout from 'pages/Layout/Layout';

import { ADMIN_ROUTES } from './AdminRoutes';

interface AdminProps {}

const Admin = (props: AdminProps) => {
    return (
        <Layout>
            <Switch>
                {Object.values(ADMIN_ROUTES[0].children).map((route) => (
                    <PrivateRoute {...route} key={route.path} />
                ))}
                <Redirect to="/admin/walle" />
            </Switch>
        </Layout>
    );
};

export default Admin;
