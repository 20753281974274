// eslint-disable-next-line import/named
import { Link, LinkProps } from 'react-router-dom';
import './SettingsLayout.scss';
import { PropsWithChildren } from 'react';
import { groupHeadersAdapter } from 'adapters/groupHeadersAdapter';

type LinkType = LinkProps & { icon: JSX.Element; name: string; group?: string };
interface SettingsLayoutProps {
    links: LinkType[];
    active?: string;
    currentUrl?: string;
    className?: string;
}

const SettingsLayout = (props: PropsWithChildren<SettingsLayoutProps>) => {
    const items = groupHeadersAdapter(props.links, 'group');

    return (
        <div className={`SettingsLayout ${props.className || ''}`}>
            <nav className="SettingsLayout__sidebar">
                {items.map((item) => {
                    if (item.type === 'header') {
                        const { group } = item;
                        if (!group) return null;
                        return <div className="SettingsLayout__group">{group}</div>;
                    }
                    const { name, icon, to, ...linkProps } = item as LinkType;
                    return (
                        <Link
                            key={name}
                            className="SettingsLayout__link"
                            aria-current={name === props.active}
                            to={(props.currentUrl || '') + to}
                            {...linkProps}
                        >
                            {icon}
                            <span>{name}</span>
                        </Link>
                    );
                })}
            </nav>
            <section className="SettingsLayout__main">{props.children}</section>
        </div>
    );
};

export default SettingsLayout;
