import IconTooltip from '../Tooltips/IconTooltip';
import './Titulos.scss';

export const TituloH1 = ({ titulo }) => {
    return <h1 className="titulo titulo__h1">{titulo}</h1>;
};

export const TituloH2 = ({ titulo, tooltip = '' }) => {
    return (
        <h2 className="titulo titulo__h2">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h2>
    );
};

export const TituloH3 = ({ titulo, tooltip = '' }) => {
    return (
        <h3 className="titulo titulo__h3">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h3>
    );
};

export const TituloH4 = ({ titulo, tooltip = '' }) => {
    return (
        <h4 className="titulo titulo__h4">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h4>
    );
};

export const TituloPromocion = ({ titulo }) => {
    return <h2 className="titulo__promocion">{titulo}</h2>;
};
