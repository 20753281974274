import { KPIStatus } from 'modules/promotion-kpis';

export const getScoreStatus = (value: number) => {
    const status: KPIStatus =
        (value >= 8 && KPIStatus.OK) || //
        (value >= 5 && KPIStatus.WARNING) || //
        KPIStatus.FAILED;

    return status;
};

export const getPercentageStatus = (value: number) => {
    const status: KPIStatus =
        (value >= 80 && KPIStatus.OK) || //
        (value >= 50 && KPIStatus.WARNING) || //
        KPIStatus.FAILED;

    return status;
};

export const getBooleanStatus = (value: boolean, niceToHave?: boolean) => {
    const status: KPIStatus =
        (!value && niceToHave && KPIStatus.NOT_APPLICABLE) || //
        (value === null && KPIStatus.INDETERMINATE) || //
        (value === true && KPIStatus.OK) || //
        KPIStatus.FAILED;
    return status;
};
