import { useEffect, useState } from 'react';
import { EmailWithOrphanDocuments } from 'modules/orphan-documents/domain/EmailWithOrphanDocuments';
import { useOrphanDocuments } from 'modules/orphan-documents';
import { IconCheck, IconClock, IconFiles } from '@tabler/icons';
import { useDateFormatter } from 'hooks/useDateFormatter';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import './WalleEmail.scss';

interface WalleEmailProps {
    email: EmailWithOrphanDocuments;
    fakeId: number; // Fake ID for checkboxes
}

const WalleEmail = ({ email, fakeId }: WalleEmailProps) => {
    const emailId = fakeId.toString();
    const { format } = useDateFormatter();
    const [{ selectedDocuments }, { selectOrphanDocuments, unselectOrphanDocuments }] = useOrphanDocuments();
    const [mainCheckboxStatus, setMainCheckboxStatus] = useState<'not-checked' | 'checked' | 'partially-checked'>(
        'not-checked'
    );

    const changeAllCheckboxes = ({ target }) => {
        const { checked } = target;

        if (checked) {
            const allDocuments = email.documents.map((doc) => ({ ...doc, emailId }));
            const documentsWithoutPromoNorDiscarded = allDocuments.filter(
                (doc) => !doc.discarded && !doc.promotionName
            );
            selectOrphanDocuments({ selectedDocuments: documentsWithoutPromoNorDiscarded });
        } else {
            const allDocumentsIds = email.documents.map((doc) => doc.extractionId);
            unselectOrphanDocuments({ selectedDocumentIds: allDocumentsIds });
        }
    };

    const toggleCheckbox = ({ target }) => {
        const { checked, value: docId } = target;
        const selectedDoc = getSelectedDocument(docId);

        if (!selectedDoc) return;

        if (checked) {
            selectOrphanDocuments({ selectedDocuments: [{ ...selectedDoc, emailId }] });
        } else {
            unselectOrphanDocuments({ selectedDocumentIds: [docId] });
        }
    };

    const getSelectedDocument = (docId) => {
        const selectedDoc = email.documents.find((doc) => doc.extractionId === docId);
        return selectedDoc;
    };

    const isDocumentSelected = (docId) => {
        const isDocumentSelected = selectedDocuments.map((doc) => doc.extractionId).includes(docId);
        return isDocumentSelected;
    };

    useEffect(() => {
        const selectedDocsInThisEmail = selectedDocuments.filter((doc) => doc.emailId === emailId);

        const emailDocsWithoutPromotionNorDiscarded = email.documents.filter(
            (doc) => !doc.discarded && !doc.promotionName
        );

        if (selectedDocsInThisEmail.length === 0) {
            setMainCheckboxStatus('not-checked');
            return;
        }

        if (emailDocsWithoutPromotionNorDiscarded.length === selectedDocsInThisEmail.length) {
            setMainCheckboxStatus('checked');
            return;
        }

        setMainCheckboxStatus('partially-checked');
    }, [selectedDocuments]);

    return (
        <section className="WalleEmail">
            <header>
                <label className="WalleEmail__mainCheckboxWrapper">
                    <div className="WalleEmail__mainInfo">
                        <input
                            id={emailId}
                            type="checkbox"
                            onChange={(event) => changeAllCheckboxes(event)}
                            checked={mainCheckboxStatus === 'checked'}
                        />
                        <span
                            className={
                                mainCheckboxStatus === 'partially-checked'
                                    ? 'WalleEmail__fakeCheckbox WalleEmail__fakeCheckbox-partial'
                                    : 'WalleEmail__fakeCheckbox'
                            }
                        ></span>
                        <p className="WalleEmail__userAndSubject">
                            <span>{email.userMail}</span>
                            <span title={email.subject}>{email.subject}</span>
                        </p>
                        <p className="WalleEmail__metaData">
                            <IconFiles />
                            <span>{email.documents.length}</span>
                        </p>
                        <p className="WalleEmail__metaData">
                            <IconClock />
                            <span>{format(email.mailTime)}</span>
                        </p>
                    </div>
                    <div className="WalleEmail__secondaryInfo">
                        <span title={email.to}>Enviado a {email.to}</span>
                    </div>
                </label>
            </header>
            <SimpleCard className="WalleEmail__documents">
                <ul>
                    {email.documents.map((document) => (
                        <li
                            key={document.extractionId}
                            className={document.discarded ? 'WalleEmail__discardedDoc' : ''}
                        >
                            <label>
                                <div>
                                    {!document.discarded && !document.promotionName && (
                                        <>
                                            <input
                                                id={document.extractionId}
                                                type="checkbox"
                                                value={document.extractionId}
                                                onChange={(event) => toggleCheckbox(event)}
                                                checked={isDocumentSelected(document.extractionId)}
                                            />
                                            <span className="WalleEmail__fakeCheckbox"></span>
                                        </>
                                    )}
                                    <span title={document.fileName || ''}>{document.fileName || '-'}</span>
                                </div>
                                {document.promotionName && (
                                    <p className="WalleEmail__promotionName">
                                        <IconCheck />
                                        <span title={document.promotionName}>{document.promotionName}</span>
                                    </p>
                                )}
                            </label>
                        </li>
                    ))}
                </ul>
            </SimpleCard>
        </section>
    );
};

export default WalleEmail;
