import { Carrier } from 'modules/carrier';

export interface WasteRegister {
    id: string;
    movementDate: string;
    weight: number | null;
    flow: {
        id: number;
        ler: {
            id: number;
            code: string;
            type: string;
        };
        manager: {
            id: number;
            name: string;
        };
        treatment: {
            code: string;
            description: string;
        };
        cost: {
            unit: number | null;
            ton: number | null;
        };
        volume: number | null;
        valuationPercentage: number | null;
    };
    carrier: Carrier;
    promotionId: number;
    promotionName: string;
    volume: number | null;
    cost: number | null;
    observation: string | null;
    updatedAt: string;
    updatedBy: string;
    createdAt: string;
    createdBy: string;
}

export type EmptyWasteRegister = Partial<WasteRegister> & Pick<WasteRegister, 'id'>;

export const createEmptyWasteRegister = (
    data: EmptyWasteRegister & Pick<WasteRegister, 'promotionId'>
): EmptyWasteRegister => ({
    ...data
});
