export const COLORS_GRAPHICS = {
    Otros: '#D2E0FB',
    Hormigón: '#ADC4CE',
    'Fracciones minerales': '#B4BDFF',
    Madera: '#FFCF96',
    Metales: '#9AD0C2',
    'Papel / Cartón': '#B99470',
    Plástico: '#fff091',
    Cerámicos: '#D6C7AE',
    Yeso: '#DC8686',
    Aislamientos: '#EAD1DC',
    Mezclas: '#9E9FA5',
    Absorbentes: '#E14790',
    'Tierra y materiales naturales excavados': '#A64B2A',
    Bituminosos: '#9E6F21',
    'Envases contaminados': '#FF6D28',
    'Aerosoles/Envases a presión': '#CC3333',
    'Pintura y barniz': '#FDF675',
    Vidrio: '#739072',
    'Tierra, lodos o materiales excavados contaminados': '#8E3200',
    'RSU orgánico': '#b5ab9a',
    'RSU vidrio': '#9c9587',
    'RSU papel': '#b5a688',
    'RSU envases': '#a69980',
    'Otros peligrosos': '#F900BF',
    'RSU fracción resto': '#96896e',
    Aceites: '#982176',
    'Residuos y fibras vegetales': '#C3EDC0',
    'Toners de impresión': '#FFB84C',
    'Equipos eléctricos y electrónicos peligrosos': '#EB5F54',
    'Tubos Fluorescentes': '#F9ED30',
    'Paneles Solares Fotovoltaicos': '#9EB384'
};
