import { IconX } from '@tabler/icons';
import { FilterModel } from 'lib/ag-grid/types/agGridTypes';
import { useFiltersTranslation } from 'lib/ag-grid/hooks/useFiltersTranslation';
import { useEffect, useState } from 'react';
import './TableFilters.scss';

type TableFiltersProps = {
    filtersObject: FilterModel | undefined;
    i18nNamespace: string;
    updateFilters: (newFilters) => void;
    customTranslateFn?: (key) => string;
};

const TableFilters = (props: TableFiltersProps) => {
    const [filters, setFilters] = useState<FilterModel | undefined>(props.filtersObject);
    const { getFilterTranslation } = useFiltersTranslation({
        i18nNamespace: props.i18nNamespace,
        customTranslateFn: props.customTranslateFn
    });

    const removeFilterByKey = (key) => {
        const filtersCopy = JSON.parse(JSON.stringify(filters));
        delete filtersCopy[key];

        setFilters(filtersCopy);
        props.updateFilters(filtersCopy);
    };

    useEffect(() => {
        setFilters(props.filtersObject);
    }, [props.filtersObject]);

    return (
        <>
            {filters && Object.keys(filters).length > 0 && (
                <div className="TableFilters">
                    Filtros aplicados:
                    {Object.keys(filters || {}).map((key, i) => (
                        <span key={i} className="TableFilters__Filter" onClick={() => removeFilterByKey(key)}>
                            {getFilterTranslation(filters[key], key)}
                            <IconX />
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

export default TableFilters;
