import {
    PROMO_TYPE_EDIFICATION_ID,
    PROMO_TYPE_PHOTOVOLTAIC_ID,
    ADDITIONAL_FIELDS_BY_PROMO_TYPE
} from '../constants/promotion.constants';

const checkIfSpecialTypeProject = (projectTypeId) => {
    if ([PROMO_TYPE_EDIFICATION_ID, PROMO_TYPE_PHOTOVOLTAIC_ID].includes(projectTypeId)) {
        return true;
    }
    return false;
};

export const getAdditionalFields = (projectTypeId) => {
    if (projectTypeId === undefined || !checkIfSpecialTypeProject(projectTypeId)) {
        return;
    }

    const type = ADDITIONAL_FIELDS_BY_PROMO_TYPE.find((type) => type?.id === projectTypeId);
    return type?.fields || [];
};
